import { ButtonGroup, Dropdown } from 'semantic-ui-react';

const btnDropdownOptions = {
    areasOfInterest: {
        add: [
            {
                key: 'Add area of interest',
                text: 'Area of interest',
                value: 'Add area of interest'
            }
        ],
        edit: [
            {
                key: 'Edit area of interest',
                text: 'Area of interest',
                value: 'Edit area of interest'
            }
        ],
        delete: [
            {
                key: 'Delete area of interest',
                text: 'Area of interest',
                value: 'Delete area of interest'
            }
        ],
        order: [
            {
                key: 'Change area of interest order',
                text: 'Area of interest',
                value: 'Change area of interest order'
            }
        ]
    },
    contact: {
        edit: [
            {
                key: 'Edit contact details',
                text: 'Contact details',
                value: 'Edit contact details'
            }
        ]
    },
    facilities: {
        add: [
            {
                key: 'Add facility',
                text: 'Facility',
                value: 'Add facility'
            }
        ],
        edit: [
            {
                key: 'Edit facility email',
                text: 'Facility email',
                value: 'Edit facility email'
            },
            {
                key: 'Edit facility',
                text: 'Facility',
                value: 'Edit facility'
            }
        ],
        delete: [
            {
                key: 'Delete facility',
                text: 'Facility',
                value: 'Delete facility'
            }
        ],
        order: [
            {
                key: 'Change facility order',
                text: 'Facility',
                value: 'Change facility order'
            }
        ]
    },
    gallery: {
        add: [
            {
                key: 'Add awards',
                text: 'Awards',
                value: 'Add awards'
            },
            {
                key: 'Add events',
                text: 'Events',
                value: 'Add events'
            },
            {
                key: 'Add lab outings',
                text: 'Lab outings',
                value: 'Add lab outings'
            }
        ],
        edit: [
            {
                key: 'Edit awards',
                text: 'Awards',
                value: 'Edit awards'
            },
            {
                key: 'Edit events',
                text: 'Events',
                value: 'Edit events'
            },
            {
                key: 'Edit lab outings',
                text: 'Lab outings',
                value: 'Edit lab outings'
            }
        ],
        delete: [
            {
                key: 'Delete awards',
                text: 'Awards',
                value: 'Delete awards'
            },
            {
                key: 'Delete events',
                text: 'Events',
                value: 'Delete events'
            },
            {
                key: 'Delete lab outings',
                text: 'Lab outings',
                value: 'Delete lab outings'
            }
        ],
        order: [
            {
                key: 'Change awards order',
                text: 'Awards',
                value: 'Change awards order'
            },
            {
                key: 'Change events order',
                text: 'Events',
                value: 'Change events order'
            },
            {
                key: 'Change lab outings order',
                text: 'Lab outings',
                value: 'Change lab outings order'
            }
        ]
    },
    home: {
        add: [
            {
                key: 'Add annual report',
                text: 'Annual report',
                value: 'Add annual report'
            }
        ],
        edit: [
            {
                key: 'Edit image',
                text: 'Image',
                value: 'Edit image'
            },
            {
                key: 'Edit card',
                text: 'Card',
                value: 'Edit card'
            },
            {
                key: 'Edit selected publications',
                text: 'Selected publications',
                value: 'Edit selected publications'
            }
        ],
        delete: [
            {
                key: 'Delete annual report',
                text: 'Annual report',
                value: 'Delete annual report'
            }
        ],
        order: [
            {
                key: 'Order card',
                text: 'Card',
                value: 'Order card'
            }
        ]
    },
    latestNews: {
        add: [
            {
                key: 'Add latest news',
                text: 'Latest news',
                value: 'Add latest news'
            }
        ],
        edit: [
            {
                key: 'Edit latest news',
                text: 'Latest news',
                value: 'Edit latest news'
            }
        ],
        delete: [
            {
                key: 'Delete latest news',
                text: 'Latest news',
                value: 'Delete latest news'
            }
        ]
    },
    groupMembers: {
        add: [
            {
                key: 'Add faculties',
                text: 'Faculties',
                value: 'Add faculties'
            },
            {
                key: 'Add postdocs/research associates',
                text: 'Postdocs/Research Associates',
                value: 'Add postdocs/research associates'
            },
            {
                key: 'Add research students',
                text: 'Research Students',
                value: 'Add research students'
            },
            {
                key: 'Add PhD alumni',
                text: 'PhD Alumni',
                value: 'Add PhD alumni'
            }
        ],
        edit: [
            {
                key: 'Edit faculties',
                text: 'Faculties',
                value: 'Edit faculties'
            },
            {
                key: 'Edit postdocs/research associates',
                text: 'Postdocs/Research Associates',
                value: 'Edit postdocs/research associates'
            },
            {
                key: 'Edit research students',
                text: 'Research Students',
                value: 'Edit research students'
            },
            {
                key: 'Edit PhD alumni',
                text: 'PhD Alumni',
                value: 'Edit PhD alumni'
            }
        ],
        delete: [
            {
                key: 'Delete faculties',
                text: 'Faculties',
                value: 'Delete faculties'
            },
            {
                key: 'Delete research students',
                text: 'Research Students',
                value: 'Delete research students'
            },
            {
                key: 'Delete postdocs/research associates',
                text: 'Postdocs/Research Associates',
                value: 'Delete postdocs/research associates text'
            },
            {
                key: 'Delete PhD alumni',
                text: 'PhD Alumni',
                value: 'Delete PhD alumni'
            }
        ],
        order: [
            {
                key: 'Change faculties order',
                text: 'Faculties',
                value: 'Change faculties order'
            },
            {
                key: 'Change postdocs/research associates order',
                text: 'Postdocs/Research Associates',
                value: 'Change postdocs/research associates order'
            },
            {
                key: 'Change research students order',
                text: 'Research Students',
                value: 'Change research students order'
            },
            {
                key: 'Change PhD alumni order',
                text: 'PhD Alumni',
                value: 'Change PhD alumni order'
            }
        ]
    },
    publications: {
        add: [
            {
                key: 'Add publications',
                text: 'Publications',
                value: 'Add publications'
            },
            {
                key: 'Add book chapters',
                text: 'Book chapters',
                value: 'Add book chapters'
            },
            {
                key: 'Add patents',
                text: 'Patents',
                value: 'Add patents'
            },
            {
                key: 'Add conference presentations',
                text: 'Conference presentations',
                value: 'Add conference presentations'
            }
        ],
        edit: [
            {
                key: 'Edit filtering options',
                text: 'Filtering options',
                value: 'Edit filtering options'
            },
            {
                key: 'Edit publications',
                text: 'Publications',
                value: 'Edit publications'
            },
            {
                key: 'Edit book chapters',
                text: 'Book chapters',
                value: 'Edit book chapters'
            },
            {
                key: 'Edit patents',
                text: 'Patents',
                value: 'Edit patents'
            },
            {
                key: 'Edit conference presentations',
                text: 'Conference presentations',
                value: 'Edit conference presentations'
            }
        ],
        delete: [
            {
                key: 'Delete publications',
                text: 'Publications',
                value: 'Delete publications'
            },
            {
                key: 'Delete book chapters',
                text: 'Book chapters',
                value: 'Delete book chapters'
            },
            {
                key: 'Delete patents',
                text: 'Patents',
                value: 'Delete patents'
            },
            {
                key: 'Delete conference presentations',
                text: 'Conference presentations',
                value: 'Delete Conference presentations'
            }
        ],
        order: [
            {
                key: 'Change publications order',
                text: 'Publications',
                value: 'Change publications order'
            },
            {
                key: 'Change book chapters order',
                text: 'Book chapters',
                value: 'Change book chapters order'
            },
            {
                key: 'Change patents order',
                text: 'Patents',
                value: 'Change patents order'
            },
            {
                key: 'Change conference presentations order',
                text: 'Conference presentations',
                value: 'Change conference presentations order'
            }
        ]
    },
    rgb: {
        add: [
            {
                key: 'Add recognition',
                text: 'Recognition',
                value: 'Add recognition'
            },
            {
                key: 'Add academic position',
                text: 'Academic position',
                value: 'Add academic position'
            },
            {
                key: 'Add research position',
                text: 'Research position',
                value: 'Add research position'
            },
            {
                key: 'Add projects handled',
                text: 'Projects handled',
                value: 'Add projects handled'
            }
        ],
        edit: [
            {
                key: 'Edit profile data',
                text: 'Profile data',
                value: 'Edit profile data'
            },
            {
                key: 'Edit recognition',
                text: 'Recognition',
                value: 'Edit recognition'
            },
            {
                key: 'Edit academic position',
                text: 'Academic position',
                value: 'Edit academic position'
            },
            {
                key: 'Edit research position',
                text: 'Research position',
                value: 'Edit research position'
            },
            {
                key: 'Edit projects handled',
                text: 'Projects handled',
                value: 'Edit projects handled'
            }
        ],
        delete: [
            {
                key: 'Delete recognition',
                text: 'Recognition',
                value: 'Delete recognition'
            },
            {
                key: 'Delete academic position',
                text: 'Academic position',
                value: 'Delete academic position'
            },
            {
                key: 'Delete research position',
                text: 'Research position',
                value: 'Delete research position'
            },
            {
                key: 'Delete projects handled',
                text: 'Projects handled',
                value: 'Delete projects handled'
            }
        ],
        order: [
            {
                key: 'Order recognition',
                text: 'Recognition',
                value: 'Order recognition'
            },
            {
                key: 'Order projects handled',
                text: 'Projects handled',
                value: 'Order projects handled'
            }
        ]
    }
};

const btnColorIconAndText = {
    add: {
        color: 'orange',
        icon: 'add',
        text: 'Add'
    },
    edit: {
        color: 'blue',
        icon: 'edit',
        text: 'Edit'
    },
    delete: {
        color: 'red',
        icon: 'trash',
        text: 'Delete'
    },
    order: {
        color: 'yellow',
        icon: 'ordered list',
        text: 'Change order'
    }
}

function DropdownButton({ btnReset, onChange, page, btn }) {
    const getOperation = (btn) => {
        if (btn.toLowerCase().includes('add')) {
            return 'add';
        }
        else if (btn.toLowerCase().includes('edit')) {
            return 'edit';
        }
        else if (btn.toLowerCase().includes('delete')) {
            return 'delete';
        }
        else if (btn.toLowerCase().includes('order')) {
            return 'order';
        }
    }

    const getCategory = (btn) => {
        if (btn.toLowerCase().includes('filtering options')) {
            return 'Filtering Options';
        }
        else if (btn.toLowerCase().includes('selected publications')) {
            return 'Selected publications';
        }
        else if (btn.toLowerCase().includes('publications')) {
            return 'Publications';
        }
        else if (btn.toLowerCase().includes('book chapters')) {
            return 'Book Chapters';
        }
        else if (btn.toLowerCase().includes('patents')) {
            return 'Patents';
        }
        else if (btn.toLowerCase().includes('conference presentations')) {
            return 'Conference Presentations';
        }
        else if (btn.toLowerCase().includes('contact details')) {
            return 'Contact Details';
        }
        else if (btn.toLowerCase().includes('facility email')) {
            return 'Facility Email';
        }
        else if (btn.toLowerCase().includes('facility')) {
            return 'Facility';
        }
        else if (btn.toLowerCase().includes('area of interest')) {
            return 'Area of Interest';
        }
        else if (btn.toLowerCase().includes('faculties')) {
            return 'Faculties';
        }
        else if (btn.toLowerCase().includes('research students')) {
            return 'Research Students';
        }
        else if (btn.toLowerCase().includes('postdocs/research associates')) {
            return 'Postdocs/Research Associates';
        }
        else if (btn.toLowerCase().includes('phd alumni')) {
            return 'PhD Alumni';
        }
        else if (btn.toLowerCase().includes('awards')) {
            return 'Awards';
        }
        else if (btn.toLowerCase().includes('events')) {
            return 'Events';
        }
        else if (btn.toLowerCase().includes('lab outings')) {
            return 'Lab Outings';
        }
        else if (btn.toLowerCase().includes('latest news')) {
            return 'Latest news';
        }
        else if (btn.toLowerCase().includes('annual report')) {
            return 'Annual report';
        }
        else if (btn.toLowerCase().includes('card')) {
            return 'Card';
        }
        else if (btn.toLowerCase().includes('image')) {
            return 'Image';
        }
        else if (btn.toLowerCase().includes('profile data')) {
            return 'Profile data';
        }
        else if (btn.toLowerCase().includes('recognition')) {
            return 'Recognition';
        }
        else if (btn.toLowerCase().includes('academic position')) {
            return 'Academic position';
        }
        else if (btn.toLowerCase().includes('research position')) {
            return 'Research position';
        }
        else if (btn.toLowerCase().includes('projects handled')) {
            return 'Projects handled';
        }
    }

    const onSelection = (btn) => {
        onChange(getOperation(btn), getCategory(btn));
    }

    return (
        <>
            <ButtonGroup color={btnColorIconAndText[btn].color} className='adminInput btnWidth'>
                <Dropdown className='icon' icon={btnColorIconAndText[btn].icon} text={btnColorIconAndText[btn].text} labeled button selection closeOnChange closeOnEscape closeOnBlur value={btnReset} selectOnBlur={false} upward={false}
                    options={btnDropdownOptions[page][btn]} onChange={(event, data) => onSelection(data.value)} />
            </ButtonGroup>
        </>
    );
}
export default DropdownButton;