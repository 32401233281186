import { useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
function AppFooter() {
    const [year] = useState((new Date()).getFullYear());
    return (
        <footer className='appFooter'>
            <div className='appFooterContaint'>
                <Header as='h2' className='color-white'>RGB's Photo & Electrocatalysis Lab</Header>
                <span>©{year} by RGB's Photo and Electrocatalysis Lab.</span>
                <div className='poweredby'><span>Powered by</span><Image className='crusoftLogo' src='https://api.cnmsrgblab.com/media/images/crusoft-logo.svg' alt='' onClick={()=> window.open('https://crusoft.in')}/></div>
            </div>
        </footer>
    );
}

export default AppFooter;
