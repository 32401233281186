import { useContext, useEffect, useState } from 'react';
import { Button, Form, FormDropdown, FormGroup, FormInput, Header, Icon } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { htmlToText, isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, Reorder, RichTextEditor } from './../Components';

function AdminPublications() {
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [filterOptionsList, setFilterOptionsList] = useState([]);
    const [yearsList, setYearsList] = useState([]);
    const [selectedYear, setSelectedYear] = useState({});
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [editValueList, setEditValueList] = useState([]);
    const [btnReset, setBtnReset] = useState('');
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const [reset, setReset] = useState(false);
    const [title, setTitle] = useState('');
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminPublications', body).then(pubRes => {
            if (!isUndefinedOrNull(pubRes)) {
                setDataToDisplay(sortArrayByElement(pubRes, 'order'));
                resetAll();
                setOperation('');
                setCategory('');
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        let years = Array.from({ length: new Date().getFullYear() - 2010 + 1 }, (value, index) => 2010 + index).map(x => {
            return { key: x, text: x, value: x }
        }).reverse();
        setYearsList(years);
        if (cat === 'Filtering Options') {
            let fillOptList = dataToDisplay.find(x => x.name === cat).list;
            setFilterOptionsList(fillOptList);
            if (!isUndefinedOrNull(fillOptList)) {
                let lastYear = fillOptList[0].values.sort().reverse()[0];
                let years = Array.from({ length: new Date().getFullYear() - lastYear }, (value, index) => lastYear + 1 + index);
                setYearsList(years.sort().map(x => { return { key: x, text: x, value: x } }));
            }
            else {
                setYearsList(years.reverse());
            }
        }
        else if (op === 'edit' || op === 'delete') {
            let editValList = sortArrayByElement(dataToDisplay.find(x => x.name === cat).list, cat === 'Publications' ? 'slNo' : 'order');
            if (cat === 'Publications') {
                editValList = editValList.reverse();
            }
            setEditValueList(editValList.map((ele) => {
                return { key: ele.id, text: htmlToText(ele.title), value: ele.id }
            }));
        }
        else {
            setEditValueList([]);
        }
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
        resetAll();
    }

    const onYearSelection = (value) => {
        if (value === '') {
            setSelectedYear({});
        }
        else {
            if (category === 'Filtering Options') {
                setSelectedYear({
                    label: value === yearsList[0].value ? value.toString() : value + ' - ' + yearsList[0].value,
                    values: Array.from({ length: value - yearsList[0].value + 1 }, (value, index) => yearsList[0].value + index).reverse()
                });
            }
            else if (category === 'Publications' || category === 'Conference Presentations') {
                setSelectedYear({ value: value });
            }
        }
    }

    const addSelectedFiltersToList = () => {
        if (!isUndefinedOrNull(selectedYear)) {
            setFilterOptionsList([selectedYear, ...filterOptionsList]);
            let years = Array.from({ length: new Date().getFullYear() - selectedYear.values[0] }, (value, index) => selectedYear.values[0] + 1 + index);
            setYearsList(years.map(x => {
                return {
                    key: x,
                    text: x,
                    value: x
                }
            }));
            setSelectedYear({});
            if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
                document.querySelector('.dropdown.icon.clear').click();
            }
        }
    }

    const onRemoveFilterOption = () => {
        let selFilters = filterOptionsList.slice(1, filterOptionsList.length);
        setFilterOptionsList(selFilters);
        let lastYear = 0;
        lastYear = isUndefinedOrNull(selFilters) ? filterOptionsList[0].values.sort()[0] - 1 : selFilters[0].values.sort().reverse()[0];
        let years = Array.from({ length: new Date().getFullYear() - lastYear }, (value, index) => lastYear + 1 + index);
        setYearsList(years.sort().map(x => {
            return {
                key: x,
                text: x,
                value: x
            }
        }));
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
    }

    const onSelectionOfvalue = (value) => {
        if (value === '') {
            setSelectedValueForEdit({});
            setSelectedYear({});
        }
        else {
            let selVal = dataToDisplay.find(x => x.name === category).list.find(y => y.id === value)
            setSelectedValueForEdit(selVal);
            setReset(false);
            setTitle(selVal?.title);
            setSelectedYear({ value: selVal?.year });
        }
        document.getElementById('inputForm')?.reset();
    }

    const resetAll = () => {
        setSelectedValueForEdit({});
        setSelectedYear({});
        setBtnReset('');
    }

    const onSave = (data = null) => {
        if (operation === 'add') {
            if (!isUndefinedOrNull(htmlToText(title)) && ((category !== 'Publications' && category !== 'Conference Presentations') || !isUndefinedOrNull(selectedYear))) {
                let body = {
                    token: token,
                    operation: operation,
                    category: category,
                    data: {
                        title: title,
                        order: dataToDisplay.find(x => x.name === category).list.length + 1,
                        id: uuidv4()
                    }
                }
                if (category === 'Publications') {
                    let catData = dataToDisplay.find(x => x.name === category).list;
                    body.data['year'] = selectedYear.value;
                    body.data['isSelected'] = false;
                    body.data['slNo'] = catData.length + 1;
                    body.data['order'] = catData.filter(y => y.year === selectedYear.value).length + 1;
                    body.data['DOILink'] = document.getElementById('doiLink').value;
                    body.data['DOIText'] = document.getElementById('doiText').value;
                }
                else if (category === 'Conference Presentations') {
                    let catData = dataToDisplay.find(x => x.name === category).list;
                    body.data['year'] = selectedYear.value;
                    body.data['slNo'] = catData.length + 1;
                    body.data['order'] = catData.filter(y => y.year === selectedYear.value).length + 1;
                }
                postData('postPublication', body).then(postPubRes => {
                    if (!isUndefinedOrNull(postPubRes)) {
                        alert('Successfully submitted.');
                        loadData();
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'edit') {
            if (category === 'Filtering Options') {
                let body = {
                    token: token,
                    operation: operation,
                    category: category,
                    data: filterOptionsList
                }
                postData('postPublication', body).then(postPubRes => {
                    if (!isUndefinedOrNull(postPubRes)) {
                        alert('Successfully submitted.');
                        loadData();
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                if (!isUndefinedOrNull(htmlToText(title)) && ((category !== 'Publications' && category !== 'Conference Presentations') || !isUndefinedOrNull(selectedYear))) {
                    let body = {
                        token: token,
                        operation: operation,
                        category: category,
                        data: {
                            title: title,
                            order: selectedValueForEdit.order,
                            id: selectedValueForEdit.id
                        }
                    }
                    if (category === 'Publications') {
                        body.data['year'] = selectedYear.value;
                        body.data['isSelected'] = selectedValueForEdit.isSelected;
                        body.data['slNo'] = selectedValueForEdit.slNo;
                        body.data['DOILink'] = document.getElementById('doiLink').value;
                        body.data['DOIText'] = document.getElementById('doiText').value;
                    }
                    else if (category === 'Conference Presentations') {
                        body.data['year'] = selectedYear.value;
                        body.data['slNo'] = selectedValueForEdit.slNo;
                    }
                    postData('postPublication', body).then(postPubRes => {
                        if (!isUndefinedOrNull(postPubRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
        }
        else if (operation === 'delete') {
            let body = {
                token: token,
                operation: operation,
                category: category,
                data: {
                    id: selectedValueForEdit.id
                }
            }
            postData('postPublication', body).then(postPubRes => {
                if (!isUndefinedOrNull(postPubRes)) {
                    alert('Successfully submitted.');
                    loadData();
                }
                else {
                    setToken(null);
                }
            });
        }
        else if (operation === 'order') {
            if (isUndefinedOrNull(data.find(x => x.newOrder === 0))) {
                let changedOrder = data.filter(x => x.newOrder !== x.order).map(y => { return { id: y.id, order: y.newOrder } });
                if (!isUndefinedOrNull(changedOrder)) {
                    let newOrderedData = [];
                    let catData = dataToDisplay.find(z => z.name === category).list;
                    changedOrder.forEach(x => {
                        let orderedData = catData.find(y => y.id === x.id);
                        orderedData.order = x.order;
                        newOrderedData.push(orderedData);
                    })
                    let body = {
                        token: token,
                        operation: operation,
                        category: category,
                        data: newOrderedData
                    }
                    postData('postPublication', body).then(postPubRes => {
                        if (!isUndefinedOrNull(postPubRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
        }
    }

    return (
        <>
            <div>
                <div className='buttonsGroup'>
                    <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='publications' btn='add' />
                    <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='publications' btn='edit' />
                    <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='publications' btn='delete' />
                    <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='publications' btn='order' />
                </div>
                <div>
                    <div className='editContainer'>
                        <Header as='h2'>
                            {operation.toUpperCase() + ' ' + category.toUpperCase()}
                        </Header>
                        {(operation === 'add' || operation === 'edit') &&
                            <>
                                {operation === 'edit' &&
                                    <>
                                        {category !== 'Filtering Options' &&
                                            <Form className='adminInputContainer' autocomplete='off'>
                                                <FormDropdown
                                                    placeholder='Select title' label='Title' className='adminInput'
                                                    fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                                    options={editValueList} selectOnBlur={false} upward={false} width='16'
                                                    onChange={(event, data) => onSelectionOfvalue(data.value)}
                                                    disabled={isUndefinedOrNull(editValueList) || editValueList.length === 0} />
                                            </Form>
                                        }
                                        {category === 'Filtering Options' &&
                                            <>
                                                {!isUndefinedOrNull(yearsList) && yearsList.length > 0 &&
                                                    <Form className='adminInputContainer' autocomplete='off'>
                                                        <FormGroup widths='equal'>
                                                            <FormDropdown
                                                                label='From year' className='adminInput disabled' options={yearsList}
                                                                fluid selection value={yearsList[0].value} />
                                                            <FormDropdown
                                                                placeholder='Select to year' label='To year' className='adminInput'
                                                                fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                                                options={yearsList} selectOnBlur={false} upward={false}
                                                                onChange={(event, data) => onYearSelection(data.value)}
                                                                disabled={isUndefinedOrNull(yearsList) || yearsList.length === 0} />
                                                            <Button icon='plus circle' color='violet' className='addFilterBtn' onClick={() => addSelectedFiltersToList()} />
                                                        </FormGroup>
                                                    </Form>
                                                }
                                                {!isUndefinedOrNull(filterOptionsList) && filterOptionsList.length > 0 &&
                                                    <>
                                                        <table className='adminFiltersContainer'>
                                                            <thead >
                                                                <tr className='adminFilter'>
                                                                    <th className='adminFilterDetails'>Label</th>
                                                                    <th className='adminFilterDetails'>Years</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filterOptionsList.map((filterOpt, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr className='adminFilter'>
                                                                                <td className='adminFilterDetails'>{filterOpt.label}</td>
                                                                                <td className='adminFilterDetails'>{filterOpt.values.join(', ')}</td>
                                                                                <td>{index === 0 && <Icon name='times circle' color='red' className='closeIcon' onClick={() => onRemoveFilterOption()} />}{index !== filterOptionsList.length - 1 && ''}</td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {(isUndefinedOrNull(yearsList) || yearsList.length === 0) &&
                                                            <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                    <Form className='adminInputContainer' autocomplete='off'>
                                        <RichTextEditor id='title' data={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.title : ''} placeholder='Enter the title here...' label='Title' required={true} onChange={(value) => { setTitle(value); setReset(false); }} reset={reset} />
                                        {category === 'Publications' &&
                                            <>
                                                <FormGroup widths='equal'>
                                                    <FormDropdown
                                                        placeholder='Select year' label='Year' className='adminInput'
                                                        fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                                        value={!isUndefinedOrNull(selectedYear) ? selectedYear.value : (!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.year : null)}
                                                        options={yearsList} selectOnBlur={false} upward={false}
                                                        onChange={(event, data) => onYearSelection(data.value)}
                                                        disabled={isUndefinedOrNull(yearsList) || yearsList.length === 0} />
                                                    <FormInput fluid label='DOI text' id='doiText' type='text' placeholder='Enter DOI text' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.DOIText : ''} />
                                                    <FormInput fluid label='DOI link' id='doiLink' type='url' placeholder='Enter DOI link' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.DOILink : ''} />
                                                </FormGroup>
                                            </>
                                        }
                                        {category === 'Conference Presentations' &&
                                            <>
                                                <FormDropdown
                                                    placeholder='Select year' label='Year' className='adminInput'
                                                    fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                                    value={!isUndefinedOrNull(selectedYear) ? selectedYear.value : (!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.year : null)}
                                                    options={yearsList} selectOnBlur={false} upward={false}
                                                    onChange={(event, data) => onYearSelection(data.value)}
                                                    disabled={isUndefinedOrNull(yearsList) || yearsList.length === 0} />
                                            </>
                                        }
                                        <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                    </Form>
                                }
                            </>
                        }
                        {operation === 'delete' &&
                            <>
                                <Form className='adminInputContainer' autocomplete='off'>
                                    <FormDropdown
                                        placeholder='Select title' label='Title' className='adminInput'
                                        fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                        options={editValueList} selectOnBlur={false} upward={false} width='16'
                                        onChange={(event, data) => onSelectionOfvalue(data.value)}
                                        disabled={isUndefinedOrNull(editValueList) || editValueList.length === 0} />
                                </Form>
                                {!isUndefinedOrNull(selectedValueForEdit) &&
                                    <>
                                        <div>
                                            <div dangerouslySetInnerHTML={{ __html: selectedValueForEdit.title }}></div>
                                            {!isUndefinedOrNull(selectedValueForEdit.DOILink) &&
                                                <>
                                                    <span>(DOI: <a href={selectedValueForEdit.DOILink} target='_blank' rel='noreferrer'>{selectedValueForEdit.DOIText}</a>)</span>
                                                </>
                                            }
                                            {!isUndefinedOrNull(selectedValueForEdit.year) &&
                                                <>
                                                    <div><b>Year:</b>{selectedValueForEdit.year}</div>
                                                </>
                                            }
                                        </div>
                                        <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                    </>
                                }
                            </>
                        }
                        {operation === 'order' &&
                            <>
                                {(category === 'Publications' || category === 'Conference Presentations') &&
                                    <>
                                        <Form className='adminInputContainer' autocomplete='off'>
                                            <FormDropdown
                                                placeholder='Select year' label='Year' className='adminInput'
                                                fluid selection closeOnChange closeOnEscape closeOnBlur required search
                                                options={yearsList} selectOnBlur={false} upward={false}
                                                onChange={(event, data) => onYearSelection(data.value)}
                                                disabled={isUndefinedOrNull(yearsList) || yearsList.length === 0} />
                                        </Form>
                                        {!isUndefinedOrNull(selectedYear) &&
                                            <>
                                                <Reorder dataToBeOrdered={dataToDisplay.find(x => x.name === category).list.filter(y => y.year === selectedYear.value)} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} />
                                            </>
                                        }
                                    </>
                                }
                                {(category !== 'Publications' && category !== 'Conference Presentations') &&
                                    <>
                                        <Reorder dataToBeOrdered={dataToDisplay.find(x => x.name === category).list} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} />
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminPublications;