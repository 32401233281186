import { useContext, useEffect, useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Button, ButtonGroup, Form, FormDropdown, FormField, Header } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { htmlToText, isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, RichTextEditor } from './../Components';

function AdminLatestNews() {
    const [dataToDisplay, setDataToDisplay] = useState();
    const [editValueList, setEditValueList] = useState([]);
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [btnReset, setBtnReset] = useState('');
    const [reset, setReset] = useState(false);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [monthNames] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminLatestNews', body).then(latestNewsRes => {
            if (!isUndefinedOrNull(latestNewsRes)) {
                setDataToDisplay(sortArrayByElement(latestNewsRes, 'date'));
                setEditValueList(latestNewsRes.map(x => {
                    return {
                        key: x.id,
                        text: htmlToText(x.title),
                        value: x.id,
                        description: x.date
                    }
                }).reverse());
                resetAll();
                setOperation('');
                setCategory('');
                setSelectedDate('');
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        resetAll();
    }

    const onSelectionOfvalue = (value) => {
        if (value === '') {
            setSelectedValueForEdit({});
        }
        else {
            setSelectedValueForEdit(dataToDisplay.find(x => x.id === value));
            setReset(false);
            setTitle(dataToDisplay.find(x => x.id === value)?.title);
            setSelectedDate(dataToDisplay.find(x => x.id === value)?.date);
        }
        document.getElementById('inputForm')?.reset();
    }

    const onDateSelection = (date) => {
        let newDate = new Date(date);
        let m = monthNames[newDate.getMonth()];
        let d = newDate.getDate();
        let y = newDate.getFullYear();
        setSelectedDate(m + ' ' + (d < 10 ? '0' + d : d) + ', ' + y);
    }

    const onSave = () => {
        setIsLoaderActive(true);
        if (operation === 'add') {
            if (!isUndefinedOrNull(selectedDate) && !isUndefinedOrNull(htmlToText(title))) {
                let body = {
                    token: token,
                    operation: operation,
                    data: {
                        date: selectedDate,
                        id: uuidv4(),
                        title: title
                    }
                }
                postData('postLatestNews', body).then(postLatNewsRes => {
                    if (!isUndefinedOrNull(postLatNewsRes)) {
                        alert('Successfully submitted.');
                        loadData();
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'edit') {
            if (!isUndefinedOrNull(selectedDate) && !isUndefinedOrNull(htmlToText(title))) {
                if (selectedValueForEdit.date !== selectedDate || selectedValueForEdit.title !== title) {
                    let body = {
                        token: token,
                        operation: operation,
                        data: {
                            date: selectedDate,
                            id: selectedValueForEdit.id,
                            title: title
                        }
                    }
                    postData('postLatestNews', body).then(postLatNewsRes => {
                        if (!isUndefinedOrNull(postLatNewsRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'delete') {
            let body = {
                token: token,
                operation: operation,
                data: {
                    id: selectedValueForEdit.id
                }
            }
            postData('postLatestNews', body).then(postLatNewsRes => {
                if (!isUndefinedOrNull(postLatNewsRes)) {
                    alert('Successfully submitted.');
                    loadData();
                }
                else {
                    setToken(null);
                }
            });
        }
    }

    const resetAll = () => {
        setSelectedValueForEdit({});
        setBtnReset('');
        setReset(true);
        setTitle('');
        setSelectedDate('');
        document.getElementById('inputForm')?.reset();
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div>
                    <ButtonGroup className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='latestNews' btn='add' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='latestNews' btn='edit' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='latestNews' btn='delete' />
                    </ButtonGroup>
                    <div>
                        <div className='editContainer'>
                            <Header as='h2'>
                                {operation.toUpperCase() + ' ' + category.toUpperCase()}
                            </Header>
                            {(operation === 'add' || operation === 'edit') &&
                                <>
                                    {operation === 'edit' &&
                                        <>
                                            <Form className='adminInputContainer'>
                                                <FormDropdown
                                                    placeholder='Select latest news' label='News' className='adminInput '
                                                    fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                                    options={editValueList} selectOnBlur={false} upward={false} width='16'
                                                    onChange={(event, data) => onSelectionOfvalue(data.value)}
                                                    disabled={isUndefinedOrNull(editValueList) || editValueList.length === 0} />
                                            </Form>
                                        </>
                                    }
                                    {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                        <>
                                            <Form className='adminInputContainer'>
                                                <RichTextEditor data={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.title : ''} id='title' placeholder='Type the title here...' label='Title' onChange={(value) => { setTitle(value); setReset(false); }} reset={reset} required={true} />
                                                <FormField label='Date' required control={SemanticDatepicker} format={'MMMM DD, YYYY'} onChange={(event, data) => onDateSelection(data.value)} value={!isUndefinedOrNull(selectedValueForEdit) ? new Date(selectedValueForEdit.date) : null} className='datePicker' datePickerOnly={true} showOutsideDays={true} />
                                            </Form>
                                            <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                        </>
                                    }
                                </>
                            }
                            {operation === 'delete' &&
                                <>
                                    <Form className='adminInputContainer'>
                                        <FormDropdown
                                            placeholder='Select latest news' label='News' className='adminInput '
                                            fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                            options={editValueList} selectOnBlur={false} upward={false} width='16'
                                            onChange={(event, data) => onSelectionOfvalue(data.value)}
                                            disabled={isUndefinedOrNull(editValueList) || editValueList.length === 0} />
                                    </Form>
                                    {!isUndefinedOrNull(selectedValueForEdit) &&
                                        <>
                                            <div>
                                                <div className='latestNewsTitle adminInput' dangerouslySetInnerHTML={{ __html: selectedValueForEdit.title }}></div>
                                                <i>{selectedValueForEdit.date}</i>
                                            </div>
                                            <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default AdminLatestNews;