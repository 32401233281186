import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postData } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { isUndefinedOrNull } from '../Service/Helpers';
import { FormInput, Icon } from 'semantic-ui-react';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { token: [, setToken] } = useContext(LocalStorage);

    let navigate = useNavigate();

    useEffect(() => {
        let localStorageToken = localStorage.getItem('token');
        let localStorageTokenTime = localStorage.getItem('tokenTime');
        if (!isUndefinedOrNull(localStorageToken)) {
            if (!isUndefinedOrNull(localStorageTokenTime) && Math.abs(new Date() - new Date(localStorageTokenTime)) > (60 * 60 * 1000)) {
                localStorage.removeItem('token');
            }
            else {
                loadData(localStorageToken);
            }
        }
    }, [])

    const handleLogin = (event) => {
        event.preventDefault();
        if (isUndefinedOrNull(username) || isUndefinedOrNull(password)) {
            alert('Username and password cannot be empty');
        }
        else {
            loadData();
        }
    };

    const loadData = (token = '') => {
        let body = {
            token: token,
            user: username,
            password: password
        }
        postData('login', body).then(loginRes => {
            if (!isUndefinedOrNull(loginRes)) {
                setToken(loginRes.token);
                localStorage.setItem('token', loginRes.token);
                localStorage.setItem('tokenTime', (new Date()).toString());
                setTimeout(() => {
                    navigate('/admin/home');
                }, 1000);
            }
            else {
                alert('Username or password is incorrect.')
                setToken(null);
            }
        });
        document.getElementById('formLogin')?.reset();
    }

    const passwordShow = () => {
        if (document.getElementById('password') !== null) {
            if (document.getElementById('password').type === 'password') {
                document.getElementById('password').type = 'text';
            }
            else {
                document.getElementById('password').type = 'password';
            }
        }
    }

    return (
        <>
            <div className='loginPage'>
                <div className='loginPageConatiner'>
                    <div className='loginHeader'>
                        <h2>Login</h2>
                    </div>
                    <div className='loginContainer'>
                        <form onSubmit={handleLogin} id='formLogin' autoComplete='off'>
                            <div className='loginInputs'>
                                <FormInput width='16' label='Username' type='text' id='username' onChange={(e) => setUsername(e.target.value)} required />
                            </div>
                            <div className='loginInputs'>
                                <FormInput width='16' label='Password' type='password' id='password' icon={<Icon name={'eye'} circular link onClick={() => passwordShow()} />} onChange={(e) => setPassword(e.target.value)} required />
                            </div>
                            <div className='loginInputs'>
                                <input type='submit' value='Login' />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
