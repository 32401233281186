import { useContext, useEffect, useState } from 'react';
import { Button, Form, FormCheckbox, FormDropdown, FormGroup, FormInput, Header, Icon, Image, Message, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { htmlToText, isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData, postFile, postImage } from '../Service/Request';
import { DropdownButton, PaginationTab, Reorder, RichTextEditor, UploadImage } from './../Components';
import { LocalStorage } from './../Service/LocalStorage';

function AdminHome() {
    const [uploadedImageData, setUploadedImageData] = useState();
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [btnReset, setBtnReset] = useState('');
    const [reset, setReset] = useState(false);
    const [description, setDescription] = useState('');
    const [cardsList, setCardsList] = useState([]);
    const [annualReportsList, setAnnualReportsList] = useState([]);
    const [navigationOptions, setNavigationOptions] = useState([]);
    const [selectedNavigation, setSelectedNavigation] = useState({});
    const { navigationList: [navigationList] } = useContext(LocalStorage);
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const [fileData, setFileData] = useState();
    const [headerCharCount, setHeaderCharCount] = useState(0);
    const [publicationsDataToDisplay, setPublicationsDataToDisplay] = useState(null);
    const [constPublicationsDataToDisplay, setConstPublicationsDataToDisplay] = useState(null);
    const [changedPubId, setChangedPubId] = useState([]);
    const [page, setPage] = useState(1);
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);
    const [yearsList, setYearsList] = useState([]);
    const [selectedYear, setSelectedYear] = useState({});
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [valueChanged, setValueChanged] = useState(false);

    useEffect(() => {
        setNavigationOptions(navigationList.map(x => { return { key: x.displayText, text: x.displayText, value: x.displayText } }));
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminHome', body).then(homeRes => {
            if (!isUndefinedOrNull(homeRes)) {
                setDataToDisplay(homeRes);
                getAdminData('adminPublications', body).then(pubRes => {
                    if (!isUndefinedOrNull(pubRes)) {
                        setPublicationsDataToDisplay(sortArrayByElement(pubRes.find(x => x.name === 'Publications').list, 'slNo'));
                        setConstPublicationsDataToDisplay(sortArrayByElement(pubRes.find(x => x.name === 'Publications').list, 'slNo'));
                        let years = Array.from({ length: new Date().getFullYear() - 2010 + 1 }, (value, index) => 2010 + index).map(x => {
                            return { key: x, text: x, value: x }
                        }).reverse();
                        setYearsList(years);
                        resetAll();
                        setOperation('');
                        setCategory('');
                    }
                    else {
                        setToken(null);
                    }
                    setIsLoaderActive(false);
                });
            }
            else {
                setToken(null);
            }
        });
    }

    const onPublicationClick = (id) => {
        setPublicationsDataToDisplay(publicationsDataToDisplay.map(val => {
            if (val.id === id) {
                val.isSelected = !val.isSelected;
                setChangedPubId([...changedPubId, id]);
            }
            return val;
        }));
        setValueChanged(true);
    }

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        resetAll();
        if (cat === 'Card') {
            setCardsList(sortArrayByElement(dataToDisplay.cardList, 'order').map(x => {
                return {
                    key: x.id,
                    text: x.header,
                    value: x.id
                }
            }));
        }
        else if (cat === 'Annual report') {
            setAnnualReportsList(dataToDisplay.annualReport.map(x => {
                return {
                    key: x.id,
                    text: x.year + ' (' + x.doc + ')',
                    value: x.id
                }
            }));
        }
    }

    const getDisplayText = (navLink) => {
        let navDetail = navigationList.find(x => x.to === navLink);
        if (!isUndefinedOrNull(navDetail)) {
            return navDetail.displayText;
        }
        else {
            let returnVal = '';
            navigationList.forEach(x => {
                if (!isUndefinedOrNull(x.sub)) {
                    let subNavDetail = x.sub.find(x => x.to === navLink);
                    if (!isUndefinedOrNull(subNavDetail)) {
                        returnVal = x.displayText;
                    }
                }
            });
            return returnVal;
        }
    }

    const getlink = (displayText) => {
        let navDetail = navigationList.find(x => x.displayText === displayText);
        if (!isUndefinedOrNull(navDetail.to)) {
            return navDetail.to;
        }
        else {
            return navDetail.sub[0].to;
        }
    }

    const onDropdownSelection = (val) => {
        if (val !== '') {
            if (category === 'Card') {
                let editVal = dataToDisplay.cardList.find(x => x.id === val);
                setSelectedValueForEdit(editVal);
                setSelectedNavigation(getDisplayText(editVal?.navigateTo));
                setDescription(editVal?.description);
                setHeaderCharCount(editVal.header.length);
                setReset(false);
            }
            else if (category === 'Annual report') {
                setSelectedValueForEdit(dataToDisplay.annualReport.find(x => x.id === val));
            }
        }
        else {
            setSelectedValueForEdit({});
            setSelectedNavigation('');
            setHeaderCharCount(0);
        }
        document.getElementById('inputForm')?.reset();
    }

    const onSave = (data = null) => {
        setIsLoaderActive(true);
        if (operation === 'add') {
            if (!isUndefinedOrNull(selectedYear) && !isUndefinedOrNull(fileData)) {
                let formData = new FormData();
                formData.append('file', fileData, fileData.name);
                formData.append('token', token);
                postFile(formData).then(postFileRes => {
                    if (!isUndefinedOrNull(postFileRes)) {
                        let body = {
                            token: token,
                            operation: operation,
                            data: {
                                id: uuidv4(),
                                doc: postFileRes.file,
                                year: selectedYear.value
                            }
                        }
                        postData('postHome', body).then(postHomeRes => {
                            if (!isUndefinedOrNull(postHomeRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'edit') {
            if (category === 'Image') {
                if (!isUndefinedOrNull(uploadedImageData)) {
                    let delBody = {
                        token: token,
                        image: [dataToDisplay.banner]
                    }
                    postData('deleteImage', delBody).then(postDelImgRes => {
                        if (!isUndefinedOrNull(postDelImgRes)) {
                            let formData = new FormData();
                            formData.append('image', uploadedImageData, uploadedImageData.name);
                            formData.append('token', token);
                            postImage(formData).then(postImgRes => {
                                if (!isUndefinedOrNull(postImgRes)) {
                                    let body = {
                                        token: token,
                                        category: category,
                                        operation: operation,
                                        data: {
                                            banner: postImgRes.image
                                        }
                                    }
                                    postData('postHome', body).then(postHomeRes => {
                                        if (!isUndefinedOrNull(postHomeRes)) {
                                            alert('Successfully submitted.');
                                            loadData();
                                        }
                                        else {
                                            setToken(null);
                                        }
                                    });
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else if (category === 'Card') {
                let headerText = document.getElementById('headerText')?.value;
                let des = htmlToText(description);
                if (!isUndefinedOrNull(headerText) && !isUndefinedOrNull(selectedNavigation) && !isUndefinedOrNull(des)) {
                    if (selectedValueForEdit.header !== headerText || selectedValueForEdit.navigateTo !== getlink(selectedNavigation) || selectedValueForEdit.description !== description || !isUndefinedOrNull(uploadedImageData)) {
                        if (des.length > 200 || headerText.length > 30) {
                            setIsLoaderActive(false);
                            alert('You exceed the maximum length in the input field.');
                        }
                        else {
                            let body = {
                                token: token,
                                category: category,
                                operation: operation,
                                data: {
                                    image: selectedValueForEdit.image,
                                    header: headerText,
                                    navigateTo: getlink(selectedNavigation),
                                    description: description,
                                    order: selectedValueForEdit.order,
                                    id: selectedValueForEdit.id
                                }
                            }
                            if (isUndefinedOrNull(uploadedImageData)) {
                                postData('postHome', body).then(postHomeRes => {
                                    if (!isUndefinedOrNull(postHomeRes)) {
                                        alert('Successfully submitted.');
                                        loadData();
                                    }
                                    else {
                                        setToken(null);
                                    }
                                });
                            }
                            else {
                                let delBody = {
                                    token: token,
                                    image: [selectedValueForEdit.image]
                                }
                                postData('deleteImage', delBody).then(postDelImgRes => {
                                    if (!isUndefinedOrNull(postDelImgRes)) {
                                        let formData = new FormData();
                                        formData.append('image', uploadedImageData, uploadedImageData.name);
                                        formData.append('token', token);
                                        postImage(formData).then(postImgRes => {
                                            if (!isUndefinedOrNull(postImgRes)) {
                                                body.data['image'] = postImgRes.image;
                                                postData('postHome', body).then(postHomeRes => {
                                                    if (!isUndefinedOrNull(postHomeRes)) {
                                                        alert('Successfully submitted.');
                                                        loadData();
                                                    }
                                                    else {
                                                        setToken(null);
                                                    }
                                                });
                                            }
                                            else {
                                                setToken(null);
                                            }
                                        });
                                    }
                                    else {
                                        setToken(null);
                                    }
                                });
                            }
                        }
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('No changes made to submit.');
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else if (category === 'Selected publications') {
                let changedValues = publicationsDataToDisplay.filter(x => changedPubId.includes(x.id));
                if (!isUndefinedOrNull(changedValues)) {
                    let body = {
                        token: token,
                        operation: 'edit-isSelected',
                        category: 'Publications',
                        data: changedValues
                    }
                    postData('postPublication', body).then(postPubRes => {
                        if (!isUndefinedOrNull(postPubRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
        }
        else if (operation === 'delete') {
            let delBody = {
                token: token,
                file: selectedValueForEdit.doc
            }
            postData('deleteFile', delBody).then(postDelFileRes => {
                if (!isUndefinedOrNull(postDelFileRes)) {
                    let body = {
                        token: token,
                        operation: operation,
                        data: {
                            id: selectedValueForEdit.id
                        }
                    }
                    postData('postHome', body).then(postHomeRes => {
                        if (!isUndefinedOrNull(postHomeRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setToken(null);
                }
            });
        }
        else if (operation === 'order') {
            if (isUndefinedOrNull(data.find(x => x.newOrder === 0))) {
                let changedOrder = data.filter(x => x.newOrder !== x.order).map(y => { return { id: y.id, order: y.newOrder } });
                if (!isUndefinedOrNull(changedOrder)) {
                    let newOrderedData = [];
                    changedOrder.forEach(x => {
                        let orderedData = dataToDisplay.cardList.find(y => y.id === x.id);
                        orderedData.order = x.order;
                        newOrderedData.push(orderedData);
                    })
                    let body = {
                        token: token,
                        operation: operation,
                        data: newOrderedData
                    }
                    postData('postHome', body).then(postHomeRes => {
                        if (!isUndefinedOrNull(postHomeRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
        }
    }

    const resetAll = () => {
        setSelectedValueForEdit({});
        setBtnReset('');
        setFileData(null);
        setSelectedYear({});
        setValueChanged(false);
        setSelectedFilter('All');
        setUploadedImageData(null);
        if (document.getElementById('search') !== null) {
            document.getElementById('search').value = '';
        }
        let charCount = document.getElementById('headerText')?.value.length;
        setHeaderCharCount(isUndefinedOrNull(charCount) ? 0 : charCount);
        setReset(true);
    }

    const onYearSelection = (value) => {
        if (value === '') {
            setSelectedYear({});
        }
        else {
            setSelectedYear({ value: value });
        }
    }

    const onRemoveFile = () => {
        setFileData(null);
        document.getElementById('file').value = '';
    }

    const onNavigationSelection = (value) => {
        if (value !== '') {
            setSelectedNavigation(value);
        }
        else {
            setSelectedNavigation('');
        }
    }

    const onFilterClick = (val) => {
        if (val !== selectedFilter) {
            document.getElementById('search').value = '';
            setSelectedFilter(val);
            if (val === 'All') {
                setPublicationsDataToDisplay(constPublicationsDataToDisplay);
            }
            else {
                setPublicationsDataToDisplay(constPublicationsDataToDisplay.filter(x => x.isSelected === (val === 'Selected')));
            }
            setPage(1);
        }
    }

    const onSearchClick = () => {
        let searchText = document.getElementById('search').value.toLowerCase();
        let tempFilterData = constPublicationsDataToDisplay;
        if (selectedFilter !== 'All') {
            tempFilterData = tempFilterData.filter(x => x.isSelected === (selectedFilter === 'Selected'));
        }
        setPublicationsDataToDisplay(tempFilterData.filter(x => x.DOILink.toLowerCase().includes(searchText) || x.DOIText.toLowerCase().includes(searchText) ||
            x.title.toLowerCase().includes(searchText) || x.year.toString().toLowerCase().includes(searchText)));
        setPage(1);
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) && !isUndefinedOrNull(constPublicationsDataToDisplay) &&
                <div>
                    <div className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='home' btn='add' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='home' btn='edit' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='home' btn='delete' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='home' btn='order' />
                    </div>
                    <div>
                        <div className='editContainer'>
                            <Header as='h2'>
                                {operation.toUpperCase() + ' ' + category.toUpperCase()}
                            </Header>
                            {operation === 'add' &&
                                <>
                                    <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                        <FormDropdown
                                            placeholder='Select year' label='Year' className='adminInput'
                                            fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                            options={yearsList} selectOnBlur={false} upward={false}
                                            onChange={(event, data) => onYearSelection(data.value)}
                                            disabled={isUndefinedOrNull(yearsList) || yearsList.length === 0} />
                                        <FormInput fluid label='Upload file' id='file' type='file' onChange={(event) => setFileData(event.target.files[0])} className='adminInput' required />
                                        {!isUndefinedOrNull(fileData) &&
                                            <>
                                                <div className='filePreview'>
                                                    <a href={URL.createObjectURL(fileData)} target='_blank' rel='noreferrer'>{fileData.name}</a>
                                                    <Icon className='fileCloseIcon' name='remove circle' size='large' color='red' onClick={() => onRemoveFile()} />
                                                </div>
                                            </>
                                        }
                                    </Form>
                                    <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                </>
                            }
                            {operation === 'edit' &&
                                <>
                                    {category === 'Image' &&
                                        <>
                                            <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                                <UploadImage imageData={(val) => { setUploadedImageData(val); setReset(false); }} isRequired={true} reset={reset} />
                                                {isUndefinedOrNull(uploadedImageData) &&
                                                    <>
                                                        <Image src={dataToDisplay.banner} className='imagePreview' />
                                                    </>
                                                }
                                            </Form>
                                            <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                        </>
                                    }
                                    {category === 'Card' &&
                                        <>
                                            <Form className='adminInputContainer' autocomplete='off'>
                                                <FormDropdown
                                                    placeholder='Select a card' label='Card' className='adminInput'
                                                    fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                                    options={cardsList} selectOnBlur={false} upward={false} width='16'
                                                    onChange={(event, data) => onDropdownSelection(data.value)}
                                                    disabled={isUndefinedOrNull(cardsList) || cardsList.length === 0} />
                                            </Form>
                                            {!isUndefinedOrNull(selectedValueForEdit) &&
                                                <>
                                                    <Form className='adminInputContainer adminForm' id='inputForm' autocomplete='off'>
                                                        <FormGroup widths='equal'>
                                                            <div>
                                                                <FormInput fluid label='Header' id='headerText' type='text' placeholder='Enter header' className='adminInput' width='16' onChange={() => setHeaderCharCount(document.getElementById('headerText')?.value.length)} defaultValue={selectedValueForEdit.header} required />
                                                                <div className='m-75'><b><small className={headerCharCount > 30 ? 'redFont' : ''}>{headerCharCount}</small><small>/30</small></b></div>
                                                            </div>
                                                            <FormDropdown
                                                                placeholder='Select main navigation' label='Navigation' className='adminInput navSubNav'
                                                                fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                                                value={!isUndefinedOrNull(selectedNavigation) ? selectedNavigation : null}
                                                                options={navigationOptions} selectOnBlur={false} upward={false}
                                                                onChange={(event, data) => onNavigationSelection(data.value, false)}
                                                                disabled={isUndefinedOrNull(navigationOptions) || navigationOptions.length === 0} />
                                                        </FormGroup>
                                                        <div>
                                                            <RichTextEditor data={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.description : ''} id='cardDescription' placeholder='Enter card description...' label='Description' onChange={(value) => { setDescription(value); setReset(false); }} reset={reset} required={true} />
                                                            <div className='m-75'><b><small className={htmlToText(description).length > 200 ? 'redFont' : ''}>{htmlToText(description).length}</small><small>/200</small></b></div>
                                                        </div>
                                                        <UploadImage imageData={(val) => { setUploadedImageData(val); setReset(false); }} required={false} reset={reset} isEdit={operation === 'edit'} />
                                                        {isUndefinedOrNull(uploadedImageData) &&
                                                            <>
                                                                <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                            </>
                                                        }
                                                    </Form>
                                                    <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                                </>
                                            }
                                        </>
                                    }
                                    {category === 'Selected publications' &&
                                        <>
                                            <div className='requestsFilter'>
                                                <Button className={selectedFilter === 'All' ? 'active filterOptions' : 'filterOptions'} label={{ as: 'a', basic: true, content: constPublicationsDataToDisplay?.length }}
                                                    labelPosition='right' onClick={() => onFilterClick('All')} content='All' />
                                                <Button className={selectedFilter === 'Not selected' ? 'active filterOptions' : 'filterOptions'} label={{ as: 'a', basic: true, content: constPublicationsDataToDisplay.filter(x => x.isSelected === false)?.length }}
                                                    labelPosition='right' onClick={() => onFilterClick('Not selected')} content='Not selected' />
                                                <Button className={selectedFilter === 'Selected' ? 'active filterOptions' : 'filterOptions'} label={{ as: 'a', basic: true, content: constPublicationsDataToDisplay.filter(x => x.isSelected === true)?.length }}
                                                    labelPosition='right' onClick={() => onFilterClick('Selected')} content='Selected' />
                                                <Form className='adminInputContainer requests' id='inputForm' autocomplete='off'>
                                                    <FormInput fluid id='search' type='text' placeholder='Search' icon={<Icon name='search' circular link onClick={() => onSearchClick()} />} />
                                                </Form>
                                            </div>
                                            {!isUndefinedOrNull(publicationsDataToDisplay) &&
                                                <div className='text-align-center mt-1'>
                                                    <Table striped celled>
                                                        <TableHeader >
                                                            <TableRow>
                                                                <TableHeaderCell width='1' textAlign='center'>Sl No</TableHeaderCell>
                                                                <TableHeaderCell width='13' textAlign='center'>Title</TableHeaderCell>
                                                                <TableHeaderCell width='2' textAlign='center'>Is Selected</TableHeaderCell>
                                                            </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                            {sortArrayByElement(publicationsDataToDisplay, 'slNo').reverse().map((publication, index) => {
                                                                return (
                                                                    <>
                                                                        {(index >= ((page - 1) * 10) && index <= ((page * 10) - 1)) &&
                                                                            <>
                                                                                <TableRow>
                                                                                    <TableCell textAlign='center'>{index + 1}</TableCell>
                                                                                    <TableCell><div dangerouslySetInnerHTML={{ __html: publication.title }}></div>
                                                                                        {!isUndefinedOrNull(publication.DOILink) &&
                                                                                            <>
                                                                                                <span>(DOI: <a href={publication.DOILink} target='_blank' rel='noreferrer'>{publication.DOIText}</a>)</span>
                                                                                            </>
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell textAlign='center' verticalAlign='center'>
                                                                                        <FormCheckbox fluid className='adminInput margin-auto' checked={publication.isSelected} onClick={() => onPublicationClick(publication.id)} />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </>
                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                    <PaginationTab data={publicationsDataToDisplay} page={page} setPage={(p) => setPage(p)} reset={reset} />
                                                </div>
                                            }
                                            {isUndefinedOrNull(publicationsDataToDisplay) &&
                                                <>
                                                    <h2>No data to display</h2>
                                                </>
                                            }
                                            <Message color='olive'>
                                                Maximum selected publications can be displayed: 10
                                                <br />
                                                {'Total selected publications: ' + constPublicationsDataToDisplay.filter(x => x.isSelected === true)?.length}
                                            </Message>
                                            {valueChanged &&
                                                <>
                                                    <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {operation === 'delete' &&
                                <>
                                    <Form className='adminInputContainer' autocomplete='off'>
                                        <FormDropdown
                                            placeholder='Select annual report' label='Annual report' className='adminInput'
                                            fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                            options={annualReportsList} selectOnBlur={false} upward={false} width='16'
                                            onChange={(event, data) => onDropdownSelection(data.value)}
                                            disabled={isUndefinedOrNull(annualReportsList) || annualReportsList.length === 0} />
                                    </Form>
                                    {!isUndefinedOrNull(selectedValueForEdit) &&
                                        <>
                                            <div>
                                                <b>File: </b><a href={selectedValueForEdit.doc} download={selectedValueForEdit.doc}><u>{selectedValueForEdit.year + ' (' + selectedValueForEdit.doc + ')'}</u></a>
                                            </div>
                                            <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                        </>
                                    }
                                </>
                            }
                            {operation === 'order' &&
                                <>
                                    <Reorder dataToBeOrdered={dataToDisplay.cardList} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} />
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default AdminHome;