import { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dimmer, Header, Image, Loader, Menu, MenuItem, Segment } from 'semantic-ui-react';
import './App.css';
import { AdminAreasOfInterest, AdminContact, AdminFacilities, AdminGallery, AdminGroupMembers, AdminHome, AdminLatestNews, AdminPublications, AdminRGB, AdminRequests, AdminUser } from './Pages';
import { isUndefinedOrNull } from './Service/Helpers';
import { LocalStorage } from './Service/LocalStorage';
import AppFooter from './Components/AppFooter';

function App() {
  const [activeMenu, setActiveMenu] = useState('Home');
  const [menuList] = useState(['Home', 'RGB', 'Areas Of Interest', 'Publications', 'Group Members', 'Facilities', 'Gallery', 'Contact', 'Latest News', 'Requests', 'User']);
  const { category } = useParams();
  const { token: [token] } = useContext(LocalStorage);
  const { isLoaderActive: [isLoaderActive, setIsLoaderActive] } = useContext(LocalStorage);
  var navigate = useNavigate();

  useEffect(() => {
    if (isUndefinedOrNull(token)) {
      setTimeout(() => {
        navigate('/login');
      }, 100);
    }
  });

  useEffect(() => {
    if (isUndefinedOrNull(token)) {
      setIsLoaderActive(false);
      localStorage.removeItem('token');
    }
  }, [token]);

  useEffect(() => {
    if (!isUndefinedOrNull(token)) {
      let nav = menuList.find(x => x.toLowerCase() === category?.toLowerCase());
      if (isUndefinedOrNull(nav)) {
        setActiveMenu('Home');
      }
      else {
        setActiveMenu(nav);
      }
    }
  }, [category])

  const onMenuSelection = (menu) => {
    setActiveMenu(menu);
  }

  setInterval(() => {
    let f = document.querySelectorAll('form');
    if (f.length > 0) {
      f.forEach(form => {
        form.setAttribute('novalidate', true);
      });
    }
  }, 1000);

  return (
    <>
      <Dimmer active={isLoaderActive} className='position-fixed'>
        <Loader content='Loading' />
      </Dimmer>
      <div className='page'>
        <div className='pageHeaderBackground'>
          <Image src='https://api.cnmsrgblab.com/media/images/adminHomeBg.jpg' alt='' className='pageHeaderImg' />
          <Header as='h1' className='pageHeader'>ADMIN PORTAL</Header>
        </div>
        <div className='pageSegment admin'>
          <Menu fluid attached='top' tabular className='publicationsMenu' activeIndex={menuList.indexOf(activeMenu)}>
            {menuList.map((menu) => {
              return (
                <>
                  <MenuItem name={menu} to={'/admin/' + menu} key={menu} as={NavLink} onClick={() => onMenuSelection(menu)}>{menu}</MenuItem>
                </>
              )
            })}
          </Menu>
          <Segment attached='bottom' className='homeSegement admin'>
            {activeMenu === 'Home' &&
              <AdminHome />
            }
            {activeMenu === 'RGB' &&
              <AdminRGB />
            }
            {activeMenu === 'Group Members' &&
              <AdminGroupMembers />
            }
            {activeMenu === 'Areas Of Interest' &&
              <AdminAreasOfInterest />
            }
            {activeMenu === 'Publications' &&
              <AdminPublications />
            }
            {activeMenu === 'Facilities' &&
              <AdminFacilities />
            }
            {activeMenu === 'Gallery' &&
              <AdminGallery />
            }
            {activeMenu === 'Latest News' &&
              <AdminLatestNews />
            }
            {activeMenu === 'Contact' &&
              <AdminContact />
            }
            {activeMenu === 'Requests' &&
              <AdminRequests />
            }
            {activeMenu === 'User' &&
              <AdminUser />
            }
          </Segment>
        </div>
        <AppFooter></AppFooter>
      </div>
    </>
  );
}

export default App;