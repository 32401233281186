import { useEffect } from 'react';
import { Pagination, Icon } from 'semantic-ui-react';


function PaginationTab({ data, page, setPage, reset = false }) {
    useEffect(() => {
        if (reset) {
            setPage(1);
        }
    }, [reset])

    return (
        <>
            <Pagination
                activePage={page}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                totalPages={Math.ceil(data.length / 10)}
                onPageChange={(e, selPage) => setPage(selPage.activePage)} />
        </>
    );
}
export default PaginationTab;