import { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, FormGroup, FormInput, Header } from 'semantic-ui-react';
import { htmlToText, isUndefinedOrNull } from '../Service/Helpers';
import { getAdminData, postData } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, RichTextEditor } from './../Components';

function AdminContact() {
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const [address, setAddress] = useState('');
    const [btnReset, setBtnReset] = useState('');
    const [reset, setReset] = useState(false);
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminContactDetails', body).then(contactDetRes => {
            if (!isUndefinedOrNull(contactDetRes)) {
                setDataToDisplay(contactDetRes);
                setAddress(contactDetRes.address);
                document.getElementById('inputForm')?.reset();
                resetAll();
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onBtnClick = (op, cat) => {
        setReset(false);
        setOperation(op);
        setCategory(cat);
    }

    const onSave = () => {
        setIsLoaderActive(true);
        let phone = document.getElementById('phone')?.value;
        let email = document.getElementById('email')?.value;
        let facebook = document.getElementById('facebook')?.value;
        let instagram = document.getElementById('instagram')?.value;
        let linkedIn = document.getElementById('linkedIn')?.value;
        let twitter = document.getElementById('twitter')?.value;
        if (!isUndefinedOrNull(htmlToText(address)) && phone !== '' && email !== '' && facebook !== '' && instagram !== '' && linkedIn !== '' && twitter !== '') {
            if (dataToDisplay.address !== address || dataToDisplay.email !== email || dataToDisplay.phoneNumber !== phone ||
                dataToDisplay.facebook !== facebook || dataToDisplay.instagram !== instagram || dataToDisplay.linkedIn !== linkedIn || dataToDisplay.twitter !== twitter) {
                let body = {
                    token: token,
                    data: {
                        address: address,
                        email: email,
                        facebook: facebook,
                        instagram: instagram,
                        linkedIn: linkedIn,
                        phoneNumber: phone,
                        twitter: twitter
                    }
                }
                postData('postContact', body).then(postContactRes => {
                    if (!isUndefinedOrNull(postContactRes)) {
                        alert('Successfully submitted.');
                        loadData();
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                setIsLoaderActive(false);
                alert('No changes to save.');
            }
        }
        else {
            setIsLoaderActive(false);
            alert('Fill required fields.');
        }
    }

    const resetAll = () => {
        setBtnReset('');
        setReset(true);
        setOperation('');
        setCategory('');
        document.getElementById('inputForm')?.reset();
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div>
                    <ButtonGroup className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='contact' btn='edit' />
                    </ButtonGroup>
                    <div className='editContainer'>
                        <Header as='h2'>{operation.toUpperCase() + ' ' + category.toUpperCase()}</Header>
                        {operation === 'edit' &&
                            <>
                                <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                    <RichTextEditor id='address' placeholder='Type the address here...' label='Address' reset={reset} data={dataToDisplay.address} onChange={(value) => { setAddress(value); setReset(false); }} required={true} />
                                    <FormGroup widths='equal'>
                                        <FormInput fluid label='Phone Number' id='phone' type='text' placeholder='Enter phone number' defaultValue={dataToDisplay.phoneNumber} required />
                                        <FormInput fluid label='Email' id='email' type='text' placeholder='Enter email' defaultValue={dataToDisplay.email} required />
                                    </FormGroup>
                                    <FormGroup widths='equal'>
                                        <FormInput fluid label='Facebook' id='facebook' type='text' placeholder='Enter Facebook link' defaultValue={dataToDisplay.facebook} required />
                                        <FormInput fluid label='Twitter' id='twitter' type='text' placeholder='Enter Twitter link' defaultValue={dataToDisplay.twitter} required />
                                    </FormGroup>
                                    <FormGroup widths='equal'>
                                        <FormInput fluid label='LinkedIn' id='linkedIn' type='text' placeholder='Enter LinkedIn link' defaultValue={dataToDisplay.linkedIn} required />
                                        <FormInput fluid label='Instagram' id='instagram' type='text' placeholder='Enter Instagram link' defaultValue={dataToDisplay.instagram} required />
                                    </FormGroup>
                                </Form>
                                <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default AdminContact;