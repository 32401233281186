import { useEffect, useState } from 'react';
import { Form, FormInput, Icon } from 'semantic-ui-react';
import { isUndefinedOrNull } from '../Service/Helpers';

function UploadImage({ imageData, isRequired = false, reset, isEdit = false, multi = false, label = 'Upload Image' }) {
    const [image, setImage] = useState('');
    const [imageFormat] = useState(['image/jpeg', 'image/png', 'image/svg', 'image/webp', 'images/gif'])
    const handleChange = (e) => {
        if (!isUndefinedOrNull(e)) {
            let selectedImage = e.target.files[0];
            if (imageFormat.includes(selectedImage.type)) {
                setImage(URL.createObjectURL(selectedImage));
                imageData(selectedImage);
            }
            else {
                alert('Invalid image format');
                document.getElementById('image').value = '';
                setImage('');
            }
            if (multi === true) {
                document.getElementById('image').value = '';
            }
        }
        else {
            imageData(null);
            document.getElementById('image').value = '';
            setImage('');
        }
    }
    useEffect(() => {
        if (reset === true) {
            document.getElementById('image').value = '';
            setImage('');
        }
    }, [reset])
    return (
        <>
            <Form className='uploadImage' autocomplete='off'>
                <FormInput fluid width='16' label={label} id='image' type='file' onChange={(event) => handleChange(event)} accept={imageFormat.join(',')} className='adminInput' required={isRequired} />
                {multi === false && !isUndefinedOrNull(image) &&
                    <>
                        <div className='position-relative width-max'>
                            <img src={image} alt='' className='imagePreview' />
                            {isEdit === true &&
                                <>
                                    <Icon className='imageCloseIcon' name='remove circle' size='large' color='red' onClick={() => handleChange(null)} />
                                </>
                            }
                        </div>
                    </>
                }
            </Form>
        </>
    );
}

export default UploadImage;