import { useContext, useEffect, useState } from 'react';
import { Button, Form, FormDropdown, FormInput, Header, Image } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { htmlToText, isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData, postImage } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, Reorder, UploadImage } from './../Components';

function AdminGallery() {
    const [uploadedImageData, setUploadedImageData] = useState(false);
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [editedCategory, setEditedCategory] = useState('');
    const [btnReset, setBtnReset] = useState('');
    const [reset, setReset] = useState(false);
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminGallery', body).then(galleryRes => {
            if (!isUndefinedOrNull(galleryRes)) {
                setDataToDisplay(sortArrayByElement(galleryRes, 'order'));
                setCategoryList(galleryRes.map(x => {
                    return {
                        key: x.id,
                        text: x.category,
                        value: x.category
                    }
                }));
                resetAll();
                setOperation('');
                setCategory('');
                setEditedCategory('');
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
        setImageList(sortArrayByElement(dataToDisplay.find(y => y.category === cat).list, 'order').map(x => {
            return {
                key: x.id,
                text: '(' + x.order + ') ; ' + (htmlToText(x.title) ?? ''),
                value: x.id
            }
        }));
        setEditedCategory(cat);
        resetAll();
    }

    const onImageSelection = (value) => {
        if (value === '') {
            setSelectedValueForEdit({});
        }
        else {
            setSelectedValueForEdit(dataToDisplay.find(x => x.category === category).list.find(x => x.id === value));
        }
        document.getElementById('inputForm')?.reset();
        setEditedCategory(category);
    }

    const onSave = (data = null) => {
        setIsLoaderActive(true);
        if (operation === 'add') {
            let titleVal = document.getElementById('title').value;
            if (!isUndefinedOrNull(uploadedImageData)) {
                let formData = new FormData();
                formData.append('image', uploadedImageData, uploadedImageData.name);
                formData.append('token', token);
                postImage(formData).then(postImgRes => {
                    if (!isUndefinedOrNull(postImgRes)) {
                        let body = {
                            token: token,
                            category: category,
                            operation: operation,
                            data: {
                                title: titleVal,
                                id: uuidv4(),
                                image: postImgRes.image,
                                order: dataToDisplay.find(x => x.category === category).list.length + 1,
                            }
                        }
                        postData('postGallery', body).then(postGalRes => {
                            if (!isUndefinedOrNull(postGalRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'edit') {
            let titleVal = document.getElementById('title').value;
            if (selectedValueForEdit.title !== titleVal || category !== editedCategory) {
                if (category !== editedCategory) {
                    let delBody = {
                        token: token,
                        category: category,
                        operation: 'delete',
                        data: {
                            id: selectedValueForEdit.id
                        }
                    }
                    postData('postGallery', delBody).then(postDelImgRes => {
                        if (!isUndefinedOrNull(postDelImgRes)) {
                            let body = {
                                token: token,
                                category: editedCategory,
                                operation: 'add',
                                data: {
                                    image: selectedValueForEdit.image,
                                    order: dataToDisplay.find(x => x.category === editedCategory).list.length + 1,
                                    title: titleVal,
                                    id: selectedValueForEdit.id
                                }
                            }
                            postData('postGallery', body).then(postGalRes => {
                                if (!isUndefinedOrNull(postGalRes)) {
                                    alert('Successfully submitted.');
                                    loadData();
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    let body = {
                        token: token,
                        category: category,
                        operation: operation,
                        data: {
                            image: selectedValueForEdit.image,
                            order: selectedValueForEdit.order,
                            title: titleVal,
                            id: selectedValueForEdit.id
                        }
                    }
                    postData('postGallery', body).then(postGalRes => {
                        if (!isUndefinedOrNull(postGalRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
            }
            else {
                setIsLoaderActive(false);
                alert('No changes made to submit.');
            }
        }
        else if (operation === 'delete') {
            let delBody = {
                token: token,
                image: [selectedValueForEdit.image]
            }
            postData('deleteImage', delBody).then(postDelImgRes => {
                if (!isUndefinedOrNull(postDelImgRes)) {
                    let body = {
                        token: token,
                        category: category,
                        operation: operation,
                        data: {
                            id: selectedValueForEdit.id
                        }
                    }
                    postData('postGallery', body).then(postGalRes => {
                        if (!isUndefinedOrNull(postGalRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setToken(null);
                }
            });

        }
        else if (operation === 'order') {
            if (isUndefinedOrNull(data.find(x => x.newOrder === 0))) {
                let changedOrder = data.filter(x => x.newOrder !== x.order).map(y => { return { id: y.id, order: y.newOrder } });
                if (!isUndefinedOrNull(changedOrder)) {
                    let newOrderedData = [];
                    changedOrder.forEach(x => {
                        let orderedData = dataToDisplay.find(z => z.category === category).list.find(y => y.id === x.id);
                        orderedData.order = x.order;
                        newOrderedData.push(orderedData);
                    })
                    let body = {
                        token: token,
                        category: category,
                        operation: operation,
                        data: newOrderedData
                    }
                    postData('postGallery', body).then(postGalRes => {
                        if (!isUndefinedOrNull(postGalRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
        }
    }

    const resetAll = () => {
        setUploadedImageData();
        setSelectedValueForEdit({});
        setBtnReset('');
        setReset(true);
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div>
                    <div className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='gallery' btn='add' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='gallery' btn='edit' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='gallery' btn='delete' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='gallery' btn='order' />
                    </div>
                    <div className='editContainer'>
                        <Header as='h2'>
                            {operation.toUpperCase() + ' ' + category.toUpperCase()}
                        </Header>
                        {(operation === 'add' || operation === 'edit') &&
                            <>
                                <Form className='adminInputContainer' autocomplete='off' id='inputForm'>
                                    {operation === 'edit' &&
                                        <>
                                            <FormDropdown
                                                placeholder='Select image to be edited' label='Image' className='adminInput'
                                                fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                                options={imageList} selectOnBlur={false} upward={false} width='16'
                                                onChange={(event, data) => onImageSelection(data.value)}
                                                disabled={isUndefinedOrNull(imageList) || imageList.length === 0} />
                                        </>
                                    }
                                    {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                        <>
                                            {operation === 'edit' &&
                                                <>
                                                    <FormDropdown
                                                        label='Category' className='adminInput'
                                                        fluid selection closeOnChange closeOnEscape closeOnBlur search required
                                                        options={categoryList} selectOnBlur={false} upward={false}
                                                        value={!isUndefinedOrNull(editedCategory) ? editedCategory : null}
                                                        onChange={(event, data) => setEditedCategory(data.value)}
                                                        disabled={isUndefinedOrNull(categoryList) || categoryList.length === 0} />
                                                    {!isUndefinedOrNull(selectedValueForEdit) &&
                                                        <div className='selectedImagePreview'>
                                                            <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {operation === 'add' &&
                                                <>
                                                    <UploadImage imageData={(val) => { setUploadedImageData(val); setReset(false); }} isRequired={true} reset={reset} />
                                                </>
                                            }
                                            {((operation === 'add' && !isUndefinedOrNull(uploadedImageData)) || operation === 'edit') &&
                                                <>
                                                    <FormInput width='16' fluid label='Title' id='title' type='text' placeholder='Enter title' className='adminInput m-0' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.title : ''} />
                                                    <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                                </>
                                            }
                                        </>
                                    }
                                </Form>
                            </>
                        }
                        {operation === 'delete' &&
                            <>
                                <Form className='adminInputContainer' autocomplete='off'>
                                    <FormDropdown
                                        placeholder='Select image to be edited' label='Image' className='adminInput'
                                        fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                        options={imageList} selectOnBlur={false} upward={false}
                                        onChange={(event, data) => onImageSelection(data.value)}
                                        disabled={isUndefinedOrNull(imageList) || imageList.length === 0} />
                                </Form>
                                {!isUndefinedOrNull(selectedValueForEdit) &&
                                    <>
                                        <div className='selectedImagePreview'>
                                            <Image src={selectedValueForEdit.image} className='imagePreview' />
                                            <div>{selectedValueForEdit.title}</div>
                                        </div>
                                        <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                    </>
                                }
                            </>
                        }
                        {operation === 'order' &&
                            <>
                                <Reorder dataToBeOrdered={dataToDisplay.find(x => x.category === category).list} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} isGalleryData={true} />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default AdminGallery;