import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function RichTextEditor({ data = '', id, placeholder = '', onChange, label, required = false, reset = false }) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const contentBlock = htmlToDraft(data);
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)));
    }, [data]);

    useEffect(() => {
        if (reset) {
            const contentBlock = htmlToDraft('');
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks)));
        }
    }, [reset]);

    const onChangeInEditor = (newState) => {
        setEditorState(newState);
        let val = draftToHtml(convertToRaw(newState.getCurrentContent()));
        onChange(val.slice(0, val.lastIndexOf('\n')));
    }

    return (
        <>
            <div className='equal width fields'>
                <div className={required ? 'field required' : 'field'}>
                    <label>{label}</label>
                    <div className='ui fluid input'>
                        <div className='richTextEditor' id={id}>
                            <Editor
                                spellCheck
                                editorState={editorState}
                                wrapperClassName='demo-wrapper'
                                editorClassName='editor'
                                placeholder={placeholder}
                                onEditorStateChange={(newState) => { onChangeInEditor(newState) }}
                                toolbar={{
                                    options: ['inline', 'link'],
                                    inline: { options: ['bold', 'italic', 'underline', 'superscript', 'subscript'] },
                                    link: { defaultTargetOption: '_blank', options: ['link', 'unlink'] }
                                }}
                                stripPastedStyles={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RichTextEditor;