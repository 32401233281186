import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import './index.css';
import { LoginForm } from './Pages';
import reportWebVitals from './reportWebVitals';
import LocalProvider from './Service/LocalStorage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <LocalProvider>
      <Routes>
        <Route path='*' element={<LoginForm />} />
        <Route path='/admin/:category' element={<App />} />
        <Route path='/login' element={<LoginForm />} />
      </Routes>
    </LocalProvider>
  </ BrowserRouter>
);
reportWebVitals();
