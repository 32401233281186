import { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, FormDropdown, FormGroup, FormInput, Header, Icon, Image, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { htmlToText, isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData, postImage } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, Reorder, RichTextEditor, UploadImage } from './../Components';

function AdminAreasOfInterest() {
    const [uploadedImageData, setUploadedImageData] = useState();
    const [relatedUploadedImageData, setRelatedUploadedImageData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [areasOfInterestList, setAreasOfInterestList] = useState([]);
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [btnReset, setBtnReset] = useState('');
    const [reset, setReset] = useState(false);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const [dataForOrder, setDataForOrder] = useState([]);
    const [orderOptions, setOrderOptions] = useState([]);
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        resetAll();
    }

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminAreasOfInterest', body).then(areasOfIntRes => {
            if (!isUndefinedOrNull(areasOfIntRes)) {
                let data = sortArrayByElement(areasOfIntRes, 'order');
                setDataToDisplay(data);
                setAreasOfInterestList(data.map(x => {
                    return {
                        key: x.id,
                        text: x.title,
                        value: x.id
                    }
                }));
                resetAll();
                setOperation('');
                setCategory('');
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onAreasOfInterestSelection = (value) => {
        if (value === '') {
            setSelectedValueForEdit({});
        }
        else {
            setReset(false);
            setSelectedValueForEdit(dataToDisplay.find(x => x.id === value));
            if (operation === 'edit') {
                setDescription(dataToDisplay.find(x => x.id === value)?.description);
                setRelatedUploadedImageData(dataToDisplay.find(x => x.id === value)?.relatedImages.map(y => {
                    return { image: null, name: '', id: y.id, url: y.image }
                }));
                setDataForOrder(dataToDisplay.find(x => x.id === value)?.relatedPublications.map(y => {
                    return { title: y.title, id: y.id, order: y.order, newOrder: y.order, link: y.link }
                }));
            }
        }
        document.getElementById('inputForm')?.reset();
    }

    const onSave = (data = null) => {
        setIsLoaderActive(true);
        if (operation === 'add') {
            let titleVal = document.getElementById('title').value;
            if (!isUndefinedOrNull(titleVal) && !isUndefinedOrNull(htmlToText(description)) && !isUndefinedOrNull(uploadedImageData)) {
                let formData = new FormData();
                formData.append('image', uploadedImageData, uploadedImageData.name);
                formData.append('token', token);
                postImage(formData).then(postImgRes => {
                    if (!isUndefinedOrNull(postImgRes)) {
                        let relatedPublicationsData = [];
                        if (!isUndefinedOrNull(dataForOrder)) {
                            relatedPublicationsData = sortArrayByElement(dataForOrder, 'newOrder').map(x => {
                                return { title: x.title, order: x.newOrder, id: uuidv4(), link: x.link }
                            });
                        }
                        let body = {
                            token: token,
                            operation: operation,
                            data: {
                                description: description,
                                image: postImgRes.image,
                                title: titleVal,
                                order: dataToDisplay.length + 1,
                                relatedImages: [],
                                relatedPublications: relatedPublicationsData,
                                id: uuidv4()
                            }
                        }
                        if (!isUndefinedOrNull(relatedUploadedImageData)) {
                            let relatedImagesUploadCount = 0;
                            let relatedImages = [];
                            relatedUploadedImageData.forEach((x) => {
                                formData = new FormData();
                                formData.append('image', x.image, x.name);
                                formData.append('token', token);
                                postImage(formData).then(postRelImgRes => {
                                    if (!isUndefinedOrNull(postRelImgRes)) {
                                        relatedImagesUploadCount++;
                                        relatedImages.push({ id: uuidv4(), image: postRelImgRes.image });
                                        if (relatedImagesUploadCount === relatedUploadedImageData.length) {
                                            body.data.relatedImages = relatedImages;
                                            postData('postAreasOfInterest', body).then(postAreaOfIntRes => {
                                                if (!isUndefinedOrNull(postAreaOfIntRes)) {
                                                    alert('Successfully submitted.');
                                                    loadData();
                                                }
                                                else {
                                                    setToken(null);
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        setToken(null);
                                    }
                                });
                            });
                        }
                        else {
                            postData('postAreasOfInterest', body).then(postAreaOfIntRes => {
                                if (!isUndefinedOrNull(postAreaOfIntRes)) {
                                    alert('Successfully submitted.');
                                    loadData();
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'edit') {
            let titleVal = document.getElementById('title').value;
            let newRelatedImages = [];
            let removedRelatedImages = [];
            let relatedImages = [];
            let isOrderChanged = !isUndefinedOrNull(dataForOrder.find(x => x.order !== x.newOrder));
            let relPubIds = selectedValueForEdit.relatedPublications.map(x => x.id);
            let isRelatedPublicationsChanged = selectedValueForEdit.relatedPublications.length !== dataForOrder.length || !isUndefinedOrNull(dataForOrder.find(x => !relPubIds.includes(x.id)));

            if (!isUndefinedOrNull(relatedUploadedImageData)) {
                relatedUploadedImageData.forEach(x => {
                    if (!isUndefinedOrNull(x.image) && (isUndefinedOrNull(selectedValueForEdit.relatedImages) || isUndefinedOrNull(selectedValueForEdit.relatedImages.find(y => y.id === x.id)))) {
                        newRelatedImages.push(x);
                    }
                });
            }
            if (!isUndefinedOrNull(selectedValueForEdit.relatedImages)) {
                selectedValueForEdit.relatedImages.forEach(x => {
                    if (isUndefinedOrNull(relatedUploadedImageData.find(y => y.id === x.id))) {
                        removedRelatedImages.push(x);
                    }
                    else {
                        relatedImages.push({ id: uuidv4(), image: x.image });
                    }
                });
            }
            let formData = new FormData();
            if (!isUndefinedOrNull(titleVal) && !isUndefinedOrNull(htmlToText(description))) {
                if (selectedValueForEdit.title !== titleVal || selectedValueForEdit.description !== description || !isUndefinedOrNull(uploadedImageData) ||
                    !isUndefinedOrNull(newRelatedImages) || !isUndefinedOrNull(removedRelatedImages) || isOrderChanged || isRelatedPublicationsChanged) {
                    let relatedPublicationsData = [];
                    if (!isUndefinedOrNull(dataForOrder)) {
                        relatedPublicationsData = sortArrayByElement(dataForOrder, 'newOrder').map(x => {
                            return { title: x.title, order: x.newOrder, id: uuidv4(), link: x.link }
                        });
                    }
                    let body = {
                        token: token,
                        operation: operation,
                        data: {
                            description: description,
                            image: selectedValueForEdit.image,
                            title: titleVal,
                            order: selectedValueForEdit.order,
                            relatedImages: relatedImages,
                            relatedPublications: relatedPublicationsData,
                            id: selectedValueForEdit.id
                        }
                    }
                    if (!isUndefinedOrNull(removedRelatedImages)) {
                        let delBody = {
                            token: token,
                            image: removedRelatedImages.map(x => x.image)
                        }
                        postData('deleteImage', delBody).then(postDelImg => {
                            if (!isUndefinedOrNull(postDelImg)) {

                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    if (!isUndefinedOrNull(newRelatedImages)) {
                        let relatedImagesUploadCount = 0;
                        newRelatedImages.forEach((x) => {
                            formData = new FormData();
                            formData.append('image', x.image, x.name);
                            formData.append('token', token);
                            postImage(formData).then(postRelImgRes => {
                                if (!isUndefinedOrNull(postRelImgRes)) {
                                    relatedImagesUploadCount++;
                                    relatedImages.push({ id: uuidv4(), image: postRelImgRes.image });
                                    if (relatedImagesUploadCount === newRelatedImages.length) {
                                        body.data.relatedImages = relatedImages;
                                        if (isUndefinedOrNull(uploadedImageData)) {
                                            postData('postAreasOfInterest', body).then(postAreaOfIntRes => {
                                                if (!isUndefinedOrNull(postAreaOfIntRes)) {
                                                    alert('Successfully submitted.');
                                                    loadData();
                                                }
                                                else {
                                                    setToken(null);
                                                }
                                            });
                                        }
                                        else {
                                            let delBody = {
                                                token: token,
                                                image: [selectedValueForEdit.image]
                                            }
                                            postData('deleteImage', delBody).then(delImgRes => {
                                                if (!isUndefinedOrNull(delImgRes)) {
                                                    formData = new FormData();
                                                    formData.append('image', uploadedImageData, uploadedImageData.name);
                                                    formData.append('token', token);
                                                    postImage(formData).then(postImgRes => {
                                                        if (!isUndefinedOrNull(postImgRes)) {
                                                            body.data.image = postImgRes.image;
                                                            postData('postAreasOfInterest', body).then(postDataRes => {
                                                                if (!isUndefinedOrNull(postDataRes)) {
                                                                    alert('Successfully submitted.');
                                                                    loadData();
                                                                }
                                                                else {
                                                                    setToken(null);
                                                                }
                                                            });
                                                        }
                                                        else {
                                                            setToken(null);
                                                        }
                                                    });
                                                }
                                                else {
                                                    setToken(null);
                                                }
                                            });
                                        }
                                    }
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        });
                    }
                    else if (isUndefinedOrNull(uploadedImageData)) {
                        postData('postAreasOfInterest', body).then(postAreaOfIntRes => {
                            if (!isUndefinedOrNull(postAreaOfIntRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        let delBody = {
                            token: token,
                            image: [...selectedValueForEdit.relatedImages.map(x => x.image), selectedValueForEdit.image]
                        }
                        postData('deleteImage', delBody).then(postDelImg => {
                            if (!isUndefinedOrNull(postDelImg)) {
                                formData = new FormData();
                                formData.append('image', uploadedImageData, uploadedImageData.name);
                                formData.append('token', token);
                                postImage(formData).then(postImgRes => {
                                    if (!isUndefinedOrNull(postImgRes)) {
                                        body.data.image = postImgRes.image;
                                        postData('postAreasOfInterest', body).then(postAreaOfIntRes => {
                                            if (!isUndefinedOrNull(postAreaOfIntRes)) {
                                                alert('Successfully submitted.');
                                                loadData();
                                            }
                                            else {
                                                setToken(null);
                                            }
                                        });
                                    }
                                    else {
                                        setToken(null);
                                    }
                                });
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'delete') {
            let delBody = {
                token: token,
                image: [selectedValueForEdit.image]
            }
            postData('deleteImage', delBody).then(postDelImg => {
                if (!isUndefinedOrNull(postDelImg)) {
                    let body = {
                        token: token,
                        operation: operation,
                        data: {
                            id: selectedValueForEdit.id
                        }
                    }
                    postData('postAreasOfInterest', body).then(postAreaOfIntRes => {
                        if (!isUndefinedOrNull(postAreaOfIntRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setToken(null);
                }
            });
        }
        else if (operation === 'order') {
            if (isUndefinedOrNull(data.find(x => x.newOrder === 0))) {
                let changedOrder = data.filter(x => x.newOrder !== x.order).map(y => { return { id: y.id, order: y.newOrder } });
                if (!isUndefinedOrNull(changedOrder)) {
                    let newOrderedData = [];
                    changedOrder.forEach(x => {
                        let orderedData = dataToDisplay.find(y => y.id === x.id);
                        orderedData.order = x.order;
                        newOrderedData.push(orderedData);
                    })
                    let body = {
                        token: token,
                        operation: operation,
                        data: newOrderedData
                    }
                    postData('postAreasOfInterest', body).then(postAreaOfIntRes => {
                        if (!isUndefinedOrNull(postAreaOfIntRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
        }
    }

    const onAddRelatedPublication = () => {
        let val = document.getElementById('relatedPublication').value;
        let valLink = document.getElementById('relatedPublicationLink').value;
        if (!isUndefinedOrNull(val) && !isUndefinedOrNull(valLink)) {
            let orderNo = dataForOrder.length + 1;
            setDataForOrder([...dataForOrder, ...[{ id: uuidv4(), title: val, order: orderNo, newOrder: orderNo, link: valLink }]]);
            document.getElementById('relatedPublication').value = '';
            document.getElementById('relatedPublicationLink').value = '';
        }
    }

    const onOrderSelection = (value, id) => {
        if (value !== '') {
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === id) {
                    x.newOrder = value;
                }
                return x
            }));
            setOrderOptions(orderOptions.filter(x => x.value !== value));
        }
        else {
            let removedOrderNo = dataForOrder.find(x => x.id === id).newOrder;
            setOrderOptions(sortArrayByElement([...orderOptions, ...[{ key: removedOrderNo, text: removedOrderNo, value: removedOrderNo }]], 'value'));
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === id) {
                    x.newOrder = 0;
                }
                return x
            }));
        }
    }

    const onRemoveRelatedPublication = (titleId) => {
        setDataForOrder(dataForOrder.filter(x => x.id !== titleId).map((z, i) => {
            z.newOrder = i + 1;
            z.order = i + 1;
            return z
        }));
        setOrderOptions([]);
    }

    const resetAll = () => {
        setSelectedValueForEdit({});
        setBtnReset('');
        setReset(true);
        setDescription('');
        setOrderOptions([]);
        setDataForOrder([]);
        setRelatedUploadedImageData([]);
        setUploadedImageData(null);
        document.getElementById('inputForm')?.reset();
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div>
                    <div className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='areasOfInterest' btn='add' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='areasOfInterest' btn='edit' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='areasOfInterest' btn='delete' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='areasOfInterest' btn='order' />
                    </div>
                    <div>
                        <div className='editContainer'>
                            <Header as='h2'>
                                {operation.toUpperCase() + ' ' + category.toUpperCase()}
                            </Header>
                            {(operation === 'add' || operation === 'edit') &&
                                <>
                                    {operation === 'edit' &&
                                        <Form className='adminInputContainer' autocomplete='off'>
                                            <FormDropdown
                                                placeholder='Select area of interest' label='Area of interest' className='adminInput'
                                                fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                                options={areasOfInterestList} selectOnBlur={false} upward={false} width='16'
                                                onChange={(event, data) => onAreasOfInterestSelection(data.value)}
                                                disabled={isUndefinedOrNull(areasOfInterestList) || areasOfInterestList.length === 0} />
                                        </Form>
                                    }
                                    {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                        <>
                                            <Form className='adminInputContainer' id='inputForm' autocomplete='off' novalidate>
                                                <FormInput fluid label='Title' id='title' type='text' placeholder='Enter area of interest title' width='16' className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.title : ''} required />
                                                <RichTextEditor data={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.description : ''} id='description' required={true} placeholder='Type the description here...' label='Description' onChange={(value) => { setDescription(value); setReset(false); }} reset={reset} />
                                                <div className='field'>
                                                    <FormGroup widths='equal'>
                                                        <FormInput fluid label='Related publication' placeholder='Enter related publication' id='relatedPublication' />
                                                        <FormInput fluid label='Link' placeholder='Enter link' id='relatedPublicationLink' />
                                                    </FormGroup>
                                                    <Button icon='add' labelPosition='left' content='Add related publication' color='orange' className='addBtn' onClick={() => onAddRelatedPublication()} />
                                                    {dataForOrder.length > 0 &&
                                                        <>
                                                            <Table striped celled>
                                                                <TableHeader >
                                                                    <TableRow>
                                                                        <TableHeaderCell width='1' textAlign='center'>Order</TableHeaderCell>
                                                                        <TableHeaderCell width='12' textAlign='center'>Title</TableHeaderCell>
                                                                        <TableHeaderCell width='2' textAlign='center'>New order</TableHeaderCell>
                                                                        <TableHeaderCell width='1' textAlign='center'>Remove</TableHeaderCell>
                                                                    </TableRow>
                                                                </TableHeader>
                                                                <TableBody>
                                                                    {dataForOrder.map((dataDis) => {
                                                                        return (
                                                                            <>
                                                                                <TableRow>
                                                                                    <TableCell textAlign='center'>{dataDis.order}</TableCell>
                                                                                    <TableCell>
                                                                                        <b>Title:</b>{dataDis.title}
                                                                                        <br />
                                                                                        <b>Link:</b><a href={dataDis.link} target='_blank' rel='noreferrer'>{dataDis.link}</a>
                                                                                    </TableCell>
                                                                                    <TableCell textAlign='center'>
                                                                                        {dataDis.newOrder !== 0 &&
                                                                                            <div className='selectedOrder'>{dataDis.newOrder}<Icon name='remove circle' color='red' className='closeIcon' onClick={() => onOrderSelection('', dataDis.id)} /></div>
                                                                                        }
                                                                                        {dataDis.newOrder === 0 &&
                                                                                            <Dropdown
                                                                                                placeholder='Select order' className='adminInput orderDropdown'
                                                                                                fluid selection closeOnChange closeOnEscape closeOnBlur search
                                                                                                options={orderOptions} selectOnBlur={false} upward={false}
                                                                                                onChange={(event, data) => onOrderSelection(data.value, dataDis.id)} />
                                                                                        }
                                                                                    </TableCell>
                                                                                    <TableCell textAlign='center'><Icon name='remove' color='red' className='closeIcon' onClick={() => onRemoveRelatedPublication(dataDis.id)} /></TableCell>
                                                                                </TableRow>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </>
                                                    }
                                                </div>
                                                <div className='mulipleImageUpload'>
                                                    <UploadImage label='Upload related images' imageData={(val) => { setRelatedUploadedImageData([...relatedUploadedImageData, { image: val, name: val.name, id: uuidv4(), url: URL.createObjectURL(val) }]); setReset(false); }} reset={reset} multi={true} isRequired={false}/>
                                                    {!isUndefinedOrNull(relatedUploadedImageData) &&
                                                        <>
                                                            <div className='multipleImages'>
                                                                {relatedUploadedImageData.map(f => {
                                                                    return (
                                                                        <>
                                                                            <div className='singleImage'>
                                                                                <div className='position-relative'>
                                                                                    <img src={f.url} alt='' className='imagePreview' key={f} />
                                                                                    <Icon className='imageCloseIcon' name='remove circle' size='large' color='red' onClick={() => setRelatedUploadedImageData(relatedUploadedImageData.filter(x => x.id !== f.id))} />
                                                                                </div>
                                                                                <div>{f.name}</div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <UploadImage imageData={(val) => { setUploadedImageData(val); setReset(false); }} isRequired={operation === 'add'} reset={reset} isEdit={operation === 'edit'} />
                                                {(isUndefinedOrNull(uploadedImageData) && !isUndefinedOrNull(selectedValueForEdit)) &&
                                                    <>
                                                        <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                    </>
                                                }
                                            </Form>
                                            <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                        </>
                                    }
                                </>
                            }
                            {operation === 'delete' &&
                                <>
                                    <Form className='adminInputContainer' autocomplete='off'>
                                        <FormDropdown
                                            placeholder='Select area of interest' label='Area of interest' className='adminInput'
                                            fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                            options={areasOfInterestList} selectOnBlur={false} upward={false} width='16'
                                            onChange={(event, data) => onAreasOfInterestSelection(data.value)}
                                            disabled={isUndefinedOrNull(areasOfInterestList) || areasOfInterestList.length === 0} />
                                    </Form>
                                    {!isUndefinedOrNull(selectedValueForEdit) &&
                                        <>
                                            <div className='selectedImagePreview'>
                                                <div className='imageAndDescription'>
                                                    <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                    <div>
                                                        <div className='facilitiesContent' dangerouslySetInnerHTML={{ __html: selectedValueForEdit.description }}></div>
                                                        {(!isUndefinedOrNull(selectedValueForEdit.relatedPublications) || !isUndefinedOrNull(selectedValueForEdit.relatedImages)) &&
                                                            <>
                                                                <div className='relatedPreview'>
                                                                    {!isUndefinedOrNull(selectedValueForEdit.relatedPublications) &&
                                                                        <div>
                                                                            <span><b>Related publications:</b></span>
                                                                            <ul>
                                                                                {sortArrayByElement(selectedValueForEdit.relatedPublications, 'order').map(pub => {
                                                                                    return (
                                                                                        <>
                                                                                            <li>
                                                                                                <span><b>Text: </b>{pub.title}</span>
                                                                                                <br />
                                                                                                <span><b>Link: </b><a href={pub.link}>{pub.link}</a></span>
                                                                                            </li>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    }
                                                                    {!isUndefinedOrNull(selectedValueForEdit.relatedImages) &&
                                                                        <div>
                                                                            <span><b>Related images:</b></span>
                                                                            <div className='multipleImages'>
                                                                                {selectedValueForEdit.relatedImages.map(f => {
                                                                                    return (
                                                                                        <>
                                                                                            <div className='singleImagePreview'>
                                                                                                <div className='position-relative'>
                                                                                                    <img src={f.image} alt='' className='deleteImagePreview' key={f} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                        </>
                                    }
                                </>
                            }
                            {operation === 'order' &&
                                <>
                                    <Reorder dataToBeOrdered={dataToDisplay} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} />
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default AdminAreasOfInterest;