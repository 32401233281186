import { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, FormDropdown, FormInput, Header, Image } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { htmlToText, isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData, postImage } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, Reorder, RichTextEditor, UploadImage } from './../Components';

function AdminFacilities() {
    const [uploadedImageData, setUploadedImageData] = useState();
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [facilityList, setFacilityList] = useState([]);
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const [btnReset, setBtnReset] = useState('');
    const [reset, setReset] = useState(false);
    const [description, setDescription] = useState('');
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        resetAll();
    }

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminFacilities', body).then(facilitiesRes => {
            if (!isUndefinedOrNull(facilitiesRes)) {
                setDataToDisplay(facilitiesRes);
                setFacilityList(sortArrayByElement(facilitiesRes.list, 'order').map(x => {
                    return {
                        key: x.id,
                        text: x.name,
                        value: x.id
                    }
                }));
                resetAll();
                setOperation('');
                setCategory('');
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onFacilitySelection = (value) => {
        if (value === '') {
            setSelectedValueForEdit({});
        }
        else {
            setSelectedValueForEdit(dataToDisplay.list.find(y => y.id === value));
            setReset(false);
            setDescription(dataToDisplay.list.find(x => x.id === value)?.description);
        }
        document.getElementById('inputForm')?.reset();
    }

    const onSave = (data = null) => {
        setIsLoaderActive(true);
        if (operation === 'add') {
            let nameVal = document.getElementById('name').value;
            if (!isUndefinedOrNull(nameVal) && !isUndefinedOrNull(htmlToText(description)) && !isUndefinedOrNull(uploadedImageData)) {
                let formData = new FormData();
                formData.append('image', uploadedImageData, uploadedImageData.name);
                formData.append('token', token);
                postImage(formData).then(postImgRes => {
                    if (!isUndefinedOrNull(postImgRes)) {
                        let body = {
                            token: token,
                            operation: operation,
                            data: {
                                description: description,
                                image: postImgRes.image,
                                name: nameVal,
                                order: dataToDisplay.list.length + 1,
                                id: uuidv4()
                            }
                        }
                        postData('postFacility', body).then(postFaciliyRes => {
                            if (!isUndefinedOrNull(postFaciliyRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
        }
        else if (operation === 'edit') {
            if (category === 'Facility Email') {
                let email = document.getElementById('email').value;
                if (dataToDisplay.contactEmail !== email) {
                    if (!isUndefinedOrNull(email)) {
                        let body = {
                            token: token,
                            category: category,
                            operation: operation,
                            data: {
                                contactEmail: email
                            }
                        }
                        postData('postFacility', body).then(postFaciliyRes => {
                            if (!isUndefinedOrNull(postFaciliyRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('Fill required fields.');
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                let nameVal = document.getElementById('name').value;
                if (!isUndefinedOrNull(nameVal) && !isUndefinedOrNull(htmlToText(description))) {
                    if (selectedValueForEdit.name !== nameVal || selectedValueForEdit.description !== description || !isUndefinedOrNull(uploadedImageData)) {
                        let body = {
                            token: token,
                            category: category,
                            operation: operation,
                            data: {
                                description: description,
                                image: selectedValueForEdit.image,
                                name: nameVal,
                                order: selectedValueForEdit.order,
                                id: selectedValueForEdit.id
                            }
                        }
                        if (isUndefinedOrNull(uploadedImageData)) {
                            postData('postFacility', body).then(postFaciliyRes => {
                                if (!isUndefinedOrNull(postFaciliyRes)) {
                                    alert('Successfully submitted.');
                                    loadData();
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                        else {
                            let delBody = {
                                token: token,
                                image: [selectedValueForEdit.image]
                            }
                            postData('deleteImage', delBody).then(postDelImgRes => {
                                if (!isUndefinedOrNull(postDelImgRes)) {
                                    let formData = new FormData();
                                    formData.append('image', uploadedImageData, uploadedImageData.name);
                                    formData.append('token', token);
                                    postImage(formData).then(postImgRes => {
                                        if (!isUndefinedOrNull(postImgRes)) {
                                            body.data['image'] = postImgRes.image;
                                            postData('postFacility', body).then(postFaciliyRes => {
                                                if (!isUndefinedOrNull(postFaciliyRes)) {
                                                    alert('Successfully submitted.');
                                                    loadData();
                                                }
                                                else {
                                                    setToken(null);
                                                }
                                            });
                                        }
                                        else {
                                            setToken(null);
                                        }
                                    });
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('No changes made to submit.');
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }

        }
        else if (operation === 'delete') {
            let delBody = {
                token: token,
                image: [selectedValueForEdit.image]
            }
            postData('deleteImage', delBody).then(postDelImgRes => {
                if (!isUndefinedOrNull(postDelImgRes)) {
                    let body = {
                        token: token,
                        operation: operation,
                        data: {
                            id: selectedValueForEdit.id
                        }
                    }
                    postData('postFacility', body).then(postFaciliyRes => {
                        if (!isUndefinedOrNull(postFaciliyRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setToken(null);
                }
            });

        }
        else if (operation === 'order') {
            if (isUndefinedOrNull(data.find(x => x.newOrder === 0))) {
                let changedOrder = data.filter(x => x.newOrder !== x.order).map(y => { return { id: y.id, order: y.newOrder } });
                if (!isUndefinedOrNull(changedOrder)) {
                    let newOrderedData = [];
                    changedOrder.forEach(x => {
                        let orderedData = dataToDisplay.list.find(y => y.id === x.id);
                        orderedData.order = x.order;
                        newOrderedData.push(orderedData);
                    })
                    let body = {
                        token: token,
                        operation: operation,
                        data: newOrderedData
                    }
                    postData('postFacility', body).then(postFaciliyRes => {
                        if (!isUndefinedOrNull(postFaciliyRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
        }
    }

    const resetAll = () => {
        setSelectedValueForEdit({});
        setBtnReset('');
        setReset(true);
        setUploadedImageData(null);
        document.getElementById('inputForm')?.reset();
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div>
                    <ButtonGroup className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='facilities' btn='add' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='facilities' btn='edit' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='facilities' btn='delete' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='facilities' btn='order' />
                    </ButtonGroup>
                    <div className='editContainer'>
                        <Header as='h2'>{operation.toUpperCase() + ' ' + category.toUpperCase()}</Header>
                        {(operation === 'add' || operation === 'edit') &&
                            <>
                                {operation === 'edit' &&
                                    <>
                                        {category === 'Facility Email' &&
                                            <>
                                                <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                                    <FormInput fluid width='16' label='Email' id='email' type='text' placeholder='Enter facility email' className='adminInput' defaultValue={dataToDisplay.contactEmail} required />
                                                </Form>
                                                <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                            </>
                                        }
                                        {category !== 'Facility Email' &&
                                            <>
                                                <Form className='adminInputContainer' autocomplete='off'>
                                                    <FormDropdown
                                                        placeholder='Select facility' label='Facility' className='adminInput'
                                                        fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                                        options={facilityList} selectOnBlur={false} upward={false} width='16'
                                                        onChange={(event, data) => onFacilitySelection(data.value)}
                                                        disabled={isUndefinedOrNull(facilityList) || facilityList.length === 0} />
                                                </Form>
                                            </>
                                        }
                                    </>
                                }
                                {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                    <>
                                        <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                            <FormInput fluid width='16' label='Name' id='name' type='text' placeholder='Enter facility name' required className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.name : ''} />
                                            <RichTextEditor data={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.description : ''} id='description' placeholder='Type the description here...' label='Description' required={true} onChange={(val) => { setDescription(val); setReset(false); }} />
                                            <UploadImage imageData={(val) => { setUploadedImageData(val); setReset(false); }} isRequired={operation === 'add'} reset={reset} isEdit={operation === 'edit'}/>
                                            {isUndefinedOrNull(uploadedImageData) && !isUndefinedOrNull(selectedValueForEdit) &&
                                                <>
                                                    <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                </>
                                            }
                                        </Form>
                                        <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                    </>
                                }
                            </>
                        }
                        {operation === 'delete' &&
                            <>
                                <Form className='adminInputContainer' autocomplete='off'>
                                    <FormDropdown
                                        placeholder='Select facility' label='Facility' className='adminInput'
                                        fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                        options={facilityList} selectOnBlur={false} upward={false} width='16'
                                        onChange={(event, data) => onFacilitySelection(data.value)}
                                        disabled={isUndefinedOrNull(facilityList) || facilityList.length === 0} />
                                </Form>
                                {!isUndefinedOrNull(selectedValueForEdit) &&
                                    <>
                                        <div className='selectedImagePreview'>
                                            <div className='imageAndDescription'>
                                                <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                <div className='facilitiesContent' dangerouslySetInnerHTML={{ __html: selectedValueForEdit.description }}></div>
                                            </div>
                                        </div>
                                        <Button icon='trash' labelPosition='left' content='Delete'  color='red' className='deleteBtn' onClick={() => onSave()} />
                                    </>
                                }
                            </>
                        }
                        {operation === 'order' &&
                            <>
                                <Reorder dataToBeOrdered={dataToDisplay.list} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default AdminFacilities;