import { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from './../Service/Helpers';

function Reorder({ dataToBeOrdered = [], reset = false, onSaveClick, isGalleryData = false }) {
    const [orderOptions, setOrderOptions] = useState([]);
    const [dataForOrder, setDataForOrder] = useState([]);

    useEffect(() => {
        if (!isUndefinedOrNull(dataToBeOrdered[0].title) || isGalleryData) {
            setDataForOrder(sortArrayByElement(dataToBeOrdered.map((x, i) => { return { title: x.title ?? '', id: x.id, order: x.order, newOrder: x.order } }), 'order'));
        }
        else if (!isUndefinedOrNull(dataToBeOrdered[0].name)) {
            setDataForOrder(sortArrayByElement(dataToBeOrdered.map((x, i) => { return { title: x.name, id: x.id, order: x.order, newOrder: x.order } }), 'order'));
        }
        else if (!isUndefinedOrNull(dataToBeOrdered[0].header)) {
            setDataForOrder(sortArrayByElement(dataToBeOrdered.map((x, i) => { return { title: x.header, id: x.id, order: x.order, newOrder: x.order } }), 'order'));
        }
    }, [dataToBeOrdered]);

    useEffect(() => {
        if (reset) {
            setOrderOptions([]);
        }
    }, [reset]);

    const onOrderSelection = (value, titleId) => {
        if (value !== '') {
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === titleId) {
                    x.newOrder = value;
                }
                return x
            }));
            setOrderOptions(orderOptions.filter(x => x.value !== value));
        }
        else {
            let removedOrderNo = dataForOrder.find(x => x.id === titleId).newOrder;
            setOrderOptions(sortArrayByElement([...orderOptions, ...[{ key: removedOrderNo, text: removedOrderNo, value: removedOrderNo }]], 'value'));
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === titleId) {
                    x.newOrder = 0;
                }
                return x
            }));
        }
    }

    const onClearAll = () => {
        setDataForOrder(dataForOrder.map(x => {
            x.newOrder = 0;
            return x
        }));
        setOrderOptions([...Array(dataForOrder.length)].map((x, i) => { return { key: i + 1, text: i + 1, value: i + 1 } }));
    }

    const onSave = () => {
        onSaveClick(dataForOrder);
    }

    return (
        <>
            <div className='reorder'>
                <Button icon='remove' labelPosition='left' content='Clear all' color='red' floated='right' className='clearBtn' onClick={() => onClearAll()} />
                <Table striped celled>
                    <TableHeader >
                        <TableRow>
                            <TableHeaderCell width='1' textAlign='center'>Order</TableHeaderCell>
                            <TableHeaderCell width='13' textAlign='center'>Title</TableHeaderCell>
                            <TableHeaderCell width='2' textAlign='center'>New order</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {dataForOrder.map((dataDis) => {
                            return (
                                <>
                                    <TableRow>
                                        <TableCell textAlign='center'>{dataDis.order}</TableCell>
                                        <TableCell><div dangerouslySetInnerHTML={{ __html: dataDis.title }}></div></TableCell>
                                        <TableCell textAlign='center'>
                                            {dataDis.newOrder !== 0 &&
                                                <div className='selectedOrder'>{dataDis.newOrder}<Icon name='remove circle' color='red' className='closeIcon' onClick={() => onOrderSelection('', dataDis.id)} /></div>
                                            }
                                            {dataDis.newOrder === 0 &&
                                                <Dropdown
                                                    placeholder='Select order' className='adminInput orderDropdown'
                                                    fluid selection closeOnChange closeOnEscape closeOnBlur search
                                                    options={orderOptions} selectOnBlur={false} upward={false}
                                                    onChange={(event, data) => onOrderSelection(data.value, dataDis.id)} />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
                <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
            </div>
        </>
    );
}

export default Reorder;
