import { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Form, FormInput, Header, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { PaginationTab } from './../Components';

function AdminRequests() {
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [data, setData] = useState(false);
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [valueChanged, setValueChanged] = useState(false);
    const [page, setPage] = useState(1);
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminRequests', body).then(requestsRes => {
            if (requestsRes !== null && requestsRes != undefined) {
                setDataToDisplay(requestsRes);
                setFilterData(requestsRes);
                setSelectedFilter('All');
                setPage(1);
                if (document.getElementById('search') !== null) {
                    document.getElementById('search').value = '';
                }
                setData(requestsRes.map(val => {
                    return { id: val.id, reviewed: val.reviewed };
                }));
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onSave = () => {
        setIsLoaderActive(true);
        let valToSave = [];
        dataToDisplay.forEach(ele => {
            if (data.find(x => x.id === ele.id).reviewed !== ele.reviewed) {
                valToSave.push(ele);
            }
        });
        if (!isUndefinedOrNull(valToSave)) {
            let body = {
                token: token,
                data: valToSave
            }
            postData('updateRequest', body).then(postUpdateReqRes => {
                if (!isUndefinedOrNull(postUpdateReqRes)) {
                    alert('Successfully submitted.');
                    loadData();
                }
                else {
                    setToken(null);
                }
            });
        }
        else {
            setIsLoaderActive(false);
            alert('No changes to save.');
        }
    }

    const onFilterClick = (val) => {
        if (val !== selectedFilter) {
            document.getElementById('search').value = '';
            setSelectedFilter(val);
            if (val === 'All') {
                setFilterData(dataToDisplay);
            }
            else {
                setFilterData(dataToDisplay.filter(x => x.reviewed === (val === 'Reviewed')));
            }
            setPage(1);
        }
    }

    const onSearchClick = () => {
        let searchText = document.getElementById('search').value.toLowerCase();
        let tempFilterData = dataToDisplay;
        if (selectedFilter !== 'All') {
            tempFilterData = dataToDisplay.filter(x => x.reviewed === (selectedFilter === 'Reviewed'));
        }
        setFilterData(tempFilterData.filter(x => x.name.toLowerCase().includes(searchText) || x.email.toLowerCase().includes(searchText) ||
            x.phone.toLowerCase().includes(searchText) || x.address.toLowerCase().includes(searchText) || x.subject.toLowerCase().includes(searchText) || x.message.toLowerCase().includes(searchText)));
        setPage(1);
    }

    const isReviewedClick = (id) => {
        setDataToDisplay(dataToDisplay.map(val => {
            if (val.id === id) {
                val.reviewed = !val.reviewed;
            }
            return val;
        }));
        setValueChanged(true);
    }

    return (
        <>
            {(dataToDisplay !== null && dataToDisplay != undefined) &&
                <div>
                    <div className='editContainer'>
                        <Header as='h2'>REQUESTS</Header>
                        <div className='requestsFilter'>
                            <Button className={selectedFilter === 'All' ? 'active filterOptions' : 'filterOptions'} label={{ as: 'a', basic: true, content: dataToDisplay?.length }}
                                labelPosition='right' onClick={() => onFilterClick('All')} content='All' />
                            <Button className={selectedFilter === 'UnReviewed' ? 'active filterOptions' : 'filterOptions'} label={{ as: 'a', basic: true, content: dataToDisplay.filter(x => x.reviewed === false)?.length }}
                                labelPosition='right' onClick={() => onFilterClick('UnReviewed')} content='UnReviewed' />
                            <Button className={selectedFilter === 'Reviewed' ? 'active filterOptions' : 'filterOptions'} label={{ as: 'a', basic: true, content: dataToDisplay.filter(x => x.reviewed === true)?.length }}
                                labelPosition='right' onClick={() => onFilterClick('Reviewed')} content='Reviewed' />
                            <Form className='adminInputContainer requests' id='inputForm' autocomplete='off'>
                                <FormInput fluid id='search' type='text' placeholder='Search' icon={<Icon name='search' circular link onClick={() => onSearchClick()} />} />
                            </Form>
                        </div>
                        <div className='requestTable'>
                            {isUndefinedOrNull(filterData) &&
                                <>
                                    <h2>No data to display</h2>
                                </>
                            }
                            {!isUndefinedOrNull(filterData) &&
                                <>
                                    <Table striped celled>
                                        <TableHeader >
                                            <TableRow>
                                                <TableHeaderCell textAlign='center'>Sl. No</TableHeaderCell>
                                                <TableHeaderCell width='2' textAlign='center'>Date & Time</TableHeaderCell>
                                                <TableHeaderCell width='2' textAlign='center'>Name</TableHeaderCell>
                                                <TableHeaderCell width='1' textAlign='center'>E-mail</TableHeaderCell>
                                                <TableHeaderCell width='1' textAlign='center'>Phone Number</TableHeaderCell>
                                                <TableHeaderCell width='2' textAlign='center'>Address</TableHeaderCell>
                                                <TableHeaderCell width='2' textAlign='center'>Subject</TableHeaderCell>
                                                <TableHeaderCell width='5' textAlign='center'>Message</TableHeaderCell>
                                                <TableHeaderCell textAlign='center'>File</TableHeaderCell>
                                                <TableHeaderCell width='1' textAlign='center'>Is Reviewed</TableHeaderCell>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {sortArrayByElement(filterData, 'timestamp').reverse().map((dataDis, index) => {
                                                return (
                                                    <>
                                                        {(index >= ((page - 1) * 10) && index <= ((page * 10) - 1)) &&
                                                            <>
                                                                <TableRow>
                                                                    <TableCell textAlign='center'>{index + 1}</TableCell>
                                                                    <TableCell>{new Date(dataDis.timestamp).toLocaleString()}</TableCell>
                                                                    <TableCell>{dataDis.name}</TableCell>
                                                                    <TableCell>{dataDis.email}</TableCell>
                                                                    <TableCell>{dataDis.phone}</TableCell>
                                                                    <TableCell>{dataDis.address}</TableCell>
                                                                    <TableCell>{dataDis.subject}</TableCell>
                                                                    <TableCell>{dataDis.message}</TableCell>
                                                                    <TableCell>
                                                                        {!isUndefinedOrNull(dataDis.file) &&
                                                                            <>
                                                                                <a href={dataDis.file} target='_blank' rel='noreferrer'><Icon name='file alternate' size='large' color='black' /></a>
                                                                            </>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell textAlign='center'>
                                                                        <Checkbox fluid className='adminInput' checked={dataDis.reviewed} onClick={() => isReviewedClick(dataDis.id)} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                    <PaginationTab data={filterData} page={page} setPage={(p) => setPage(p)} />
                                </>
                            }
                        </div>
                        {valueChanged &&
                            <>
                                <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default AdminRequests;