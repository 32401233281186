import { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, FormCheckbox, FormDropdown, FormGroup, FormInput, FormTextArea, Header, Icon, Image, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData, postImage } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, Reorder, UploadImage } from './../Components';

function AdminGroupMembers() {
    const [btnReset, setBtnReset] = useState('');
    const [reset, setReset] = useState(false);
    const [uploadedImageData, setUploadedImageData] = useState();
    const [orderOptions, setOrderOptions] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [dataForOrder, setDataForOrder] = useState([]);
    const [category, setCategory] = useState('');
    const [operation, setOperation] = useState('');
    const [editedCategory, setEditedCategory] = useState('');
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);
    const [isValueChanged, setIsValueChanged] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminGroupMembers', body).then(grpMemRes => {
            if (!isUndefinedOrNull(grpMemRes)) {
                setDataToDisplay(sortArrayByElement(grpMemRes, 'order'));
                resetAll();
                setOperation('');
                setCategory('');
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        resetAll();
        if (op === 'edit' || op === 'delete') {
            setMemberList(sortArrayByElement(dataToDisplay.find(x => x.category === cat).list, 'order').map(x => {
                return {
                    key: x.id,
                    text: x.name,
                    value: x.id,
                }
            }));
            setEditedCategory(cat);
        }
    }

    const onAddRecognition = () => {
        let val = document.getElementById('recognition').value;
        if (!isUndefinedOrNull(val) && val !== '') {
            let orderNo = dataForOrder.length + 1;
            setDataForOrder([...dataForOrder, ...[{ id: uuidv4(), title: val, order: orderNo, newOrder: orderNo }]]);
            document.getElementById('recognition').value = '';
        }
    }

    const onOrderSelection = (value, titleId) => {
        if (value !== '') {
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === titleId) {
                    x.newOrder = value;
                }
                return x
            }));
            setOrderOptions(orderOptions.filter(x => x.value !== value));
        }
        else {
            let removedOrderNo = dataForOrder.find(x => x.id === titleId).newOrder;
            setOrderOptions(sortArrayByElement([...orderOptions, ...[{ key: removedOrderNo, text: removedOrderNo, value: removedOrderNo }]], 'value'));
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === titleId) {
                    x.newOrder = 0;
                }
                return x
            }));
        }
    }

    const onRemoveRecognition = (titleId) => {
        setDataForOrder(dataForOrder.filter(x => x.id !== titleId).map((z, i) => {
            z.newOrder = i + 1;
            z.order = i + 1;
            return z
        }));
        setOrderOptions([]);
    }

    const onMemberSelection = (val) => {
        if (isUndefinedOrNull(val)) {
            setSelectedValueForEdit({});
        }
        else {
            let member = dataToDisplay.find(x => x.category === category).list.find(y => y.id === val);
            setSelectedValueForEdit(member);
            if (!isUndefinedOrNull(member.recognitions)) {
                setDataForOrder(sortArrayByElement(member.recognitions, 'order').map(x => {
                    return { id: x.id, title: x.name, order: x.order, newOrder: x.order }
                }));
            }
            else {
                setDataForOrder([]);
            }
        }
        document.getElementById('inputForm')?.reset();
    }

    const onSave = (data = null) => {
        setIsLoaderActive(true);
        if (operation === 'order') {
            if (isUndefinedOrNull(data.find(x => x.newOrder === 0))) {
                let changedOrder = data.filter(x => x.newOrder !== x.order).map(y => { return { id: y.id, order: y.newOrder } });
                if (!isUndefinedOrNull(changedOrder)) {
                    let newOrderedData = [];
                    let catData = dataToDisplay.find(z => z.category === category).list;
                    changedOrder.forEach(x => {
                        let orderedData = catData.find(y => y.id === x.id);
                        orderedData.order = x.order;
                        newOrderedData.push(orderedData);
                    })
                    let body = {
                        token: token,
                        operation: operation,
                        category: category,
                        data: newOrderedData
                    }
                    postData('postGroupMember', body).then(postGrpMemRes => {
                        if (!isUndefinedOrNull(postGrpMemRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
        }
        else if (operation === 'delete') {
            let delBody = {
                token: token,
                image: [selectedValueForEdit.image]
            }
            postData('deleteImage', delBody).then(postDelImgRes => {
                if (!isUndefinedOrNull(postDelImgRes)) {
                    let body = {
                        token: token,
                        category: category,
                        operation: operation,
                        data: {
                            id: selectedValueForEdit.id
                        }
                    }
                    postData('postGroupMember', body).then(postGrpMemRes => {
                        if (!isUndefinedOrNull(postGrpMemRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setToken(null);
                }
            });
        }
        else if (category !== 'PhD Alumni') {
            let name = document.getElementById('name')?.value;
            let role = document.getElementById('role')?.value;
            let email = document.getElementById('email')?.value;
            let researchTitle = document.getElementById('researchTitle')?.value;
            let phd = document.getElementById('phd')?.value;
            let msc = document.getElementById('msc')?.value;
            let bsc = document.getElementById('bsc')?.value;
            let isProfileRequired = document.getElementById('profileRequired')?.checked;
            let recognitions = [];
            if (!isUndefinedOrNull(dataForOrder)) {
                recognitions = sortArrayByElement(dataForOrder, 'newOrder').map(x => {
                    return { name: x.title, order: x.newOrder, id: uuidv4() }
                });
            }
            let body = {
                token: token,
                category: category,
                operation: operation,
                data: {
                    name: name,
                    role: role,
                    email: email,
                    researchTitle: researchTitle,
                    phd: phd,
                    msc: msc,
                    bsc: bsc,
                    recognitions: recognitions,
                    profileRequired: isProfileRequired
                }
            }
            if (!isUndefinedOrNull(dataForOrder) && !isUndefinedOrNull(dataForOrder.find(x => x.newOrder === 0))) {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
            else if (isUndefinedOrNull(name) || isUndefinedOrNull(role)) {
                setIsLoaderActive(false);
                alert('Fill all the required fields.');
            }
            else if (operation === 'add') {
                if (!isUndefinedOrNull(uploadedImageData)) {
                    let formData = new FormData();
                    formData.append('image', uploadedImageData, uploadedImageData.name);
                    formData.append('token', token);
                    postImage(formData).then(postImgRes => {
                        if (!isUndefinedOrNull(postImgRes)) {
                            body.data.image = postImgRes.image;
                            body.data.order = dataToDisplay.find(x => x.category === category).list.length + 1;
                            body.data.id = uuidv4();
                            postData('postGroupMember', body).then(postGrpMemRes => {
                                if (!isUndefinedOrNull(postGrpMemRes)) {
                                    alert('Successfully submitted.');
                                    loadData();
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else if (operation === 'edit') {
                let isOrderChanged = !isUndefinedOrNull(dataForOrder.find(x => x.order !== x.newOrder));
                let recogIds = selectedValueForEdit.recognitions.map(x => x.id);
                let isRecognitionChanged = selectedValueForEdit.recognitions.length !== dataForOrder.length || !isUndefinedOrNull(dataForOrder.find(x => !recogIds.includes(x.id)));
                if (selectedValueForEdit.name !== name || selectedValueForEdit.role !== role || selectedValueForEdit.email !== email || isValueChanged ||
                    selectedValueForEdit.researchTitle !== researchTitle || selectedValueForEdit.phd !== phd || selectedValueForEdit.msc !== msc ||
                    selectedValueForEdit.bsc !== bsc || !isUndefinedOrNull(uploadedImageData) || isOrderChanged || isRecognitionChanged || category !== editedCategory) {
                    body.data.order = selectedValueForEdit.order;
                    body.data.id = selectedValueForEdit.id;
                    if (isUndefinedOrNull(uploadedImageData)) {
                        if (category !== editedCategory) {
                            let delBody = {
                                token: token,
                                category: category,
                                operation: 'delete',
                                data: {
                                    id: selectedValueForEdit.id
                                }
                            }
                            postData('postGroupMember', delBody).then(postDelGrpMemRes => {
                                if (!isUndefinedOrNull(postDelGrpMemRes)) {
                                    body.category = editedCategory;
                                    body.operation = 'add';
                                    body.data.order = dataToDisplay.find(x => x.category === editedCategory)?.list.length + 1;
                                    postData('postGroupMember', body).then(postGrpMemRes => {
                                        body.data.image = selectedValueForEdit.image;
                                        if (!isUndefinedOrNull(postGrpMemRes)) {
                                            alert('Successfully submitted.');
                                            loadData();
                                        }
                                        else {
                                            setToken(null);
                                        }
                                    });
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                        else {
                            body.data.image = selectedValueForEdit.image;
                            postData('postGroupMember', body).then(postGrpMemRes => {
                                if (!isUndefinedOrNull(postGrpMemRes)) {
                                    alert('Successfully submitted.');
                                    loadData();
                                }
                                else {
                                    setToken(null);
                                }
                            });
                        }
                    }
                    else {
                        let delBody = {
                            token: token,
                            image: [selectedValueForEdit.image]
                        }
                        postData('deleteImage', delBody).then(postDelImgRes => {
                            if (!isUndefinedOrNull(postDelImgRes)) {
                                let formData = new FormData();
                                formData.append('image', uploadedImageData, uploadedImageData.name);
                                formData.append('token', token);
                                postImage(formData).then(postImgRes => {
                                    if (!isUndefinedOrNull(postImgRes)) {
                                        body.data.image = postImgRes.image;
                                        if (category !== editedCategory) {
                                            let delBody = {
                                                token: token,
                                                category: category,
                                                operation: 'delete',
                                                data: {
                                                    id: selectedValueForEdit.id
                                                }
                                            }
                                            postData('postGroupMember', delBody).then(postDelGrpMemRes => {
                                                if (!isUndefinedOrNull(postDelGrpMemRes)) {
                                                    body.category = editedCategory;
                                                    body.operation = 'add';
                                                    body.data.order = dataToDisplay.find(x => x.category === editedCategory)?.list.length + 1;
                                                    postData('postGroupMember', body).then(postGrpMemRes => {
                                                        if (!isUndefinedOrNull(postGrpMemRes)) {
                                                            alert('Successfully submitted.');
                                                            loadData();
                                                        }
                                                        else {
                                                            setToken(null);
                                                        }
                                                    });
                                                }
                                                else {
                                                    setToken(null);
                                                }
                                            });
                                        }
                                        else {
                                            postData('postGroupMember', body).then(postGrpMemRes => {
                                                if (!isUndefinedOrNull(postGrpMemRes)) {
                                                    alert('Successfully submitted.');
                                                    loadData();
                                                }
                                                else {
                                                    setToken(null);
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        setToken(null);
                                    }
                                });
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes are made to save.');
                }
            }
        }
        else {
            let name = document.getElementById('name')?.value;
            if (operation === 'add') {
                if (!isUndefinedOrNull(name)) {
                    let body = {
                        token: token,
                        category: category,
                        operation: operation,
                        data: {
                            name: name,
                            order: dataToDisplay.find(x => x.category === category).list.length + 1,
                            id: uuidv4()
                        }
                    }
                    postData('postGroupMember', body).then(postGrpMemRes => {
                        if (!isUndefinedOrNull(postGrpMemRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else if (operation === 'edit') {
                if (!isUndefinedOrNull(name)) {
                    if (selectedValueForEdit.name !== name) {
                        let body = {
                            token: token,
                            category: category,
                            operation: operation,
                            data: {
                                name: name,
                                order: selectedValueForEdit.order,
                                id: selectedValueForEdit.id
                            }
                        }
                        postData('postGroupMember', body).then(postGrpMemRes => {
                            if (!isUndefinedOrNull(postGrpMemRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('No changes are made to save.');
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
        }
    }

    const onProfileRequiredClick = () => {
        let selVal = selectedValueForEdit;
        selVal.profileRequired = !selVal.profileRequired;
        setSelectedValueForEdit(selVal);
        setIsValueChanged(true);
    }

    const resetAll = () => {
        setSelectedValueForEdit({});
        document.getElementById('inputForm')?.reset();
        setOrderOptions([]);
        setDataForOrder([]);
        setBtnReset('');
        setReset(true);
        setIsValueChanged(false);
        setUploadedImageData();
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
    }
    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div>
                    <div className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='groupMembers' btn='add' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='groupMembers' btn='edit' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='groupMembers' btn='delete' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='groupMembers' btn='order' />
                    </div>
                    <div className='editContainer'>
                        <Header as='h2'>
                            {operation.toUpperCase() + ' ' + category.toUpperCase()}
                        </Header>
                        {category === 'PhD Alumni' &&
                            <>
                                {(operation === 'edit' || operation === 'delete') &&
                                    <Form className='adminInputContainer' autocomplete='off'>
                                        <FormDropdown
                                            placeholder='Select member to be edited' label='Member' className='adminInput'
                                            fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                            options={memberList} selectOnBlur={false} upward={false}
                                            onChange={(event, data) => onMemberSelection(data.value)}
                                            disabled={isUndefinedOrNull(memberList) || memberList.length === 0} />
                                    </Form>
                                }
                                {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                    <>
                                        <Form className='adminInputContainer' id='inputForm' autocomplete='off' noValidation={true}>
                                            <FormInput fluid label='Name' id='name' type='text' placeholder='Enter name' required className='adminInput textInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.name : ''} />
                                        </Form>
                                        <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                    </>
                                }
                                {operation === 'delete' && !isUndefinedOrNull(selectedValueForEdit) &&
                                    <>
                                        <div>
                                            <div className='memberDeleteDetail'>
                                                <Header as='h5' className='m-0'>Name:</Header>
                                                <span>{selectedValueForEdit.name}</span>
                                            </div>
                                        </div>
                                        <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                    </>
                                }
                            </>
                        }
                        {category !== 'PhD Alumni' &&
                            <>
                                {(operation === 'add' || operation === 'edit') &&
                                    <>
                                        {operation === 'edit' &&
                                            <Form className='adminInputContainer' autocomplete='off'>
                                                <FormDropdown
                                                    placeholder='Select member to be edited' label='Member' className='adminInput'
                                                    fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                                    options={memberList} selectOnBlur={false} upward={false} width='16'
                                                    onChange={(event, data) => onMemberSelection(data.value)}
                                                    disabled={isUndefinedOrNull(memberList) || memberList.length === 0} />
                                            </Form>
                                        }
                                        {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                            <>
                                                <Form className='adminInputContainer' id='inputForm' autocomplete='off' noValidation={true}>
                                                    <FormGroup widths='equal'>
                                                        <FormInput fluid label='Name' id='name' type='text' placeholder='Enter name' required className='adminInput textInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.name : ''} />
                                                        {operation === 'edit' &&
                                                            <FormDropdown
                                                                label='Category' className='adminInput' fluid selection closeOnChange closeOnEscape closeOnBlur required search
                                                                options={[{ key: 'Faculties', text: 'Faculties', value: 'Faculties' },
                                                                { key: 'Postdocs/Research Associates', text: 'Postdocs/Research Associates', value: 'Postdocs/Research Associates' },
                                                                { key: 'Research Students', text: 'Research Students', value: 'Research Students' },
                                                                { key: 'PhD Alumni', text: 'PhD Alumni', value: 'PhD Alumni' }]}
                                                                value={!isUndefinedOrNull(editedCategory) ? editedCategory : null}
                                                                selectOnBlur={false} upward={false} onChange={(event, data) => setEditedCategory(data.value)} />
                                                        }
                                                        <FormCheckbox fluid label='Is profile required' id='profileRequired' className='adminInput' defaultChecked={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.profileRequired : true} onClick={() => onProfileRequiredClick()} />
                                                    </FormGroup>
                                                    <FormGroup widths='equal'>
                                                        <FormInput fluid label='Role' id='role' type='text' placeholder='Enter role' className='adminInput' required defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.role : ''} />
                                                        <FormInput fluid label='Email' id='email' type='text' placeholder='Enter email' className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.email : ''} />
                                                    </FormGroup>
                                                    <FormGroup widths='equal'>
                                                        <FormInput fluid label='Research Title' id='researchTitle' type='text' placeholder='Enter research title' className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.researchTitle : ''} />
                                                        <FormInput fluid label='PhD College' id='phd' type='text' placeholder='Enter PhD college' className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.phd : ''} />
                                                    </FormGroup>
                                                    <FormGroup widths='equal'>
                                                        <FormInput fluid label='MSC College' id='msc' type='text' placeholder='Enter MSC college' className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.msc : ''} />
                                                        <FormInput fluid label='BSC College' id='bsc' type='text' placeholder='Enter BSC college' className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.bsc : ''} />
                                                    </FormGroup>
                                                    <div className='field'>
                                                        <FormTextArea label='Recognition' rows={4} placeholder='Enter recognition' id='recognition' onKeyDown={(event) => (event.key === 'Tab' || event.key === 'Enter' ? event.preventDefault() : true)} />
                                                        <Button icon='add' labelPosition='left' content='Add recognition' color='orange' className='addBtn' onClick={() => onAddRecognition()} />
                                                        {dataForOrder.length > 0 &&
                                                            <>
                                                                <Table striped celled>
                                                                    <TableHeader >
                                                                        <TableRow>
                                                                            <TableHeaderCell width='1' textAlign='center'>Order</TableHeaderCell>
                                                                            <TableHeaderCell width='12' textAlign='center'>Title</TableHeaderCell>
                                                                            <TableHeaderCell width='2' textAlign='center'>New order</TableHeaderCell>
                                                                            <TableHeaderCell width='1' textAlign='center'>Remove</TableHeaderCell>
                                                                        </TableRow>
                                                                    </TableHeader>
                                                                    <TableBody>
                                                                        {dataForOrder.map((dataDis) => {
                                                                            return (
                                                                                <>
                                                                                    <TableRow>
                                                                                        <TableCell textAlign='center'>{dataDis.order}</TableCell>
                                                                                        <TableCell>{dataDis.title}</TableCell>
                                                                                        <TableCell textAlign='center'>
                                                                                            {dataDis.newOrder !== 0 &&
                                                                                                <div className='selectedOrder'>{dataDis.newOrder}<Icon name='remove circle' color='red' className='closeIcon' onClick={() => onOrderSelection('', dataDis.id)} /></div>
                                                                                            }
                                                                                            {dataDis.newOrder === 0 &&
                                                                                                <Dropdown
                                                                                                    placeholder='Select order' className='adminInput orderDropdown'
                                                                                                    fluid selection closeOnChange closeOnEscape closeOnBlur search
                                                                                                    options={orderOptions} selectOnBlur={false} upward={false}
                                                                                                    onChange={(event, data) => onOrderSelection(data.value, dataDis.id)} />
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell textAlign='center'><Icon name='remove' color='red' className='closeIcon' onClick={() => onRemoveRecognition(dataDis.id)} /></TableCell>
                                                                                    </TableRow>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </>
                                                        }
                                                    </div>
                                                    <UploadImage imageData={(val) => { setUploadedImageData(val); setReset(false); }} isRequired={operation === 'add'} reset={reset} isEdit={operation === 'edit'}/>
                                                    {(isUndefinedOrNull(uploadedImageData) && !isUndefinedOrNull(selectedValueForEdit)) &&
                                                        <>
                                                            <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                        </>
                                                    }
                                                </Form>
                                                <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                            </>
                                        }
                                    </>
                                }
                                {operation === 'delete' &&
                                    <>
                                        <Form className='adminInputContainer' autocomplete='off'>
                                            <FormDropdown
                                                placeholder='Select member to be edited' label='Member' className='adminInput'
                                                fluid selection closeOnChange closeOnEscape closeOnBlur required clearable search
                                                options={memberList} selectOnBlur={false} upward={false}
                                                onChange={(event, data) => onMemberSelection(data.value)}
                                                disabled={isUndefinedOrNull(memberList) || memberList.length === 0} />
                                        </Form>
                                        {!isUndefinedOrNull(selectedValueForEdit) &&
                                            <>
                                                <div className='imageAndDescription'>
                                                    <Image src={selectedValueForEdit.image} className='imagePreview' />
                                                    <div>
                                                        {!isUndefinedOrNull(selectedValueForEdit.name) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>Name:</Header>
                                                                <span>{selectedValueForEdit.name}</span>
                                                            </div>
                                                        }
                                                        {!isUndefinedOrNull(selectedValueForEdit.role) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>Role:</Header>
                                                                <span>{selectedValueForEdit.role}</span>
                                                            </div>
                                                        }
                                                        {!isUndefinedOrNull(selectedValueForEdit.email) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>Email:</Header>
                                                                <span>{selectedValueForEdit.email}</span>
                                                            </div>
                                                        }
                                                        {!isUndefinedOrNull(selectedValueForEdit.researchTitle) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>Research title:</Header>
                                                                <span>{selectedValueForEdit.researchTitle}</span>
                                                            </div>
                                                        } {!isUndefinedOrNull(selectedValueForEdit.phd) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>PhD:</Header>
                                                                <span>{selectedValueForEdit.phd}</span>
                                                            </div>
                                                        }
                                                        {!isUndefinedOrNull(selectedValueForEdit.msc) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>MSC:</Header>
                                                                <span>{selectedValueForEdit.msc}</span>
                                                            </div>
                                                        }
                                                        {!isUndefinedOrNull(selectedValueForEdit.bsc) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>BSC:</Header>
                                                                <span>{selectedValueForEdit.bsc}</span>
                                                            </div>
                                                        }
                                                        {!isUndefinedOrNull(selectedValueForEdit.recognitions) &&
                                                            <div className='memberDeleteDetail'>
                                                                <Header as='h5' className='m-0'>Recognitions:</Header>
                                                                <span>
                                                                    {!isUndefinedOrNull(selectedValueForEdit.recognitions) &&
                                                                        <>
                                                                            <ul className='m-0'>
                                                                                {selectedValueForEdit.recognitions.map(y => {
                                                                                    return (
                                                                                        <>
                                                                                            <li>{y.title}</li>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </>
                                                                    }
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {operation === 'order' &&
                            <>
                                <Reorder dataToBeOrdered={dataToDisplay.find(x => x.category === category).list} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default AdminGroupMembers;