import { useContext, useState } from 'react';
import { Button, Form, FormInput, Header, Icon } from 'semantic-ui-react';
import { LocalStorage } from '../Service/LocalStorage';
import { postData } from '../Service/Request';
import { isUndefinedOrNull } from '../Service/Helpers';

function AdminUser() {
    const [resetPassword, setResetPassword] = useState(false);
    const { token: [token, setToken] } = useContext(LocalStorage);
    const onLogOutClick = () => {
        let body = {
            token: token
        }
        postData('logout', body).then(logOutRes => {
            setToken(null);
        });
    }

    const passwordShow = (id) => {
        if (document.getElementById(id) !== null) {
            if (document.getElementById(id).type === 'password') {
                document.getElementById(id).type = 'text';
            }
            else {
                document.getElementById(id).type = 'password';
            }
        }
    }

    const onSave = () => {
        let currentPass = document.getElementById('currentPass')?.value;
        let newPass = document.getElementById('newPass')?.value;
        let confirmNewPass = document.getElementById('confirmNewPass')?.value;
        if (!isUndefinedOrNull(currentPass) && !isUndefinedOrNull(newPass) && !isUndefinedOrNull(confirmNewPass)) {
            if (newPass === confirmNewPass) {
                let body = {
                    token: token,
                    data: {
                        currentPassword: currentPass,
                        newPassword: newPass
                    }
                }
                postData('resetPassword', body).then(postResetPass => {
                    if (!isUndefinedOrNull(postResetPass)) {
                        if (postResetPass.data === 'Reset successful') {
                            alert('Password reset successful, please login again.');
                            setToken(null);
                        }
                        else {
                            alert(postResetPass.data);
                        }
                    }
                    else {
                        setToken(null);
                    }
                });
            }
            else {
                alert('New password and Confirm new password mismatch');
            }
        }
        else {
            alert('Fill all the required fields.');
        }
    }

    return (
        <>
            <div>
                <div className='buttonsGroup'>
                    <Button content='Log-out' color='red' icon='log out' labelPosition='right' onClick={() => onLogOutClick()} />
                    <Button content='Reset password' color='blue' icon='key' labelPosition='right' onClick={() => setResetPassword(true)} />
                </div>
                {resetPassword &&
                    <>
                        <Header as='h2'>RESET PASSWORD</Header>
                        <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                            <FormInput fluid width='6' label='Current password' id='currentPass' type='password' placeholder='Enter current password' icon={<Icon name={'eye'} circular link onClick={() => passwordShow('currentPass')} />} required />
                            <FormInput fluid width='6' label='New password' id='newPass' type='password' placeholder='Enter new password' icon={<Icon name={'eye'} circular link onClick={() => passwordShow('newPass')} />} required />
                            <FormInput fluid width='6' label='Confirm new password' id='confirmNewPass' type='password' placeholder='Enter new password' icon={<Icon name={'eye'} circular link onClick={() => passwordShow('confirmNewPass')} />} required />
                        </Form>
                        <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                    </>
                }
            </div>
        </>
    );
}

export default AdminUser;